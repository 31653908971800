@import './guideVar.scss';

html,
body {
	margin: 0;
	padding: 0;
	background-color: white !important;
	overflow-x: hidden;
	overflow-y: hidden;
}

@mixin animate($animation,$duration,$method,$times){
    animation: $animation $duration $method $times;
}

@mixin keyframes($name){
    @keyframes #{$name}{
        @content;
    }
}

@include keyframes(fade){
	0%{
		opacity: 0;
	}
	50%{
		opacity: 0.5;
		transform: translatey(-10px);
	}
	100%{
		opacity: 1;
	}
}

@include keyframes(fillRight){
	0%{
		width: 0;
	}
	50%{
		width: 50%;
	}
	100%{
		width: 100%;
	}
}

@include keyframes(textUp) {
	0%{
		top: 0;
	}
	20%{
		top: -2px;
	}
	40%{
		top: 0;
	}
	100%{
		top: 0;
	}
}

@include keyframes(moveRight) {
	0%, 14.29% {  // Stay off-screen left for 300ms (300/2100*100)
        transform: translateX(-100%);
    }
    52.38% {      // Move across screen in 800ms
        transform: translateX(200%);
    }
    100% {        // Stay off-screen right for 1000ms until repeat
        transform: translateX(200%);
    }
}

.Chat {	
	&__chatWrap {
		width: 100%;
		display: flex;
		flex-direction: column;
		background: $color-white;
		height: calc(100vh - 86px);
		// padding-top: 32px;

		@media screen and (max-width: 960px) {
			padding: 0 $spacing16;
		}

		@media screen and (max-width: 600px) {
			// padding-top: $spacing16;
		}
		
		&__md {
			@extend .Chat__chatWrap;
			height: calc(100% - 86px);
			padding: 0;
		}
	}

	&__chatWrapDlst {
		width: 100%;
		display: flex;
		flex-direction: column;
		background: $color-white;
		height: calc(100vh - 86px);
		// padding-top: 32px;

		@media screen and (max-width: 960px) {
			padding: 0 $spacing16;
		}

		@media screen and (max-width: 600px) {
			// padding-top: $spacing16;
		}
		
		&__md {
			@extend .Chat__chatWrapDlst;
			height: calc(100% - 86px);
			padding: 0;
		}
	}

	&__wrapper {
		height: 100%;
		display: flex;
		flex-direction: column;
	}
	
	&__list {
		width: 100%;
		height: 100%;
		max-width: $max-width-web-chat;
		// padding-bottom: 180px;
	}
	
	&__recommend {
		width: fit-content;
		background-color: #FFFFFF !important;
		
		@mixin button-style {
			min-height: 32px;
			background-color: #F5F5F5 !important;
			border: 1px solid #CCC !important;
			border-radius: 32px !important;
			font-size: 14px !important;
			color: black !important;
			white-space: nowrap;
			box-shadow: none !important;
			font-weight: 600;
			text-align: start;
			margin-right: 4px;
			
			&:hover {
				background-color: #D8D8D8 !important;
			}
		}

		&__button {
			@include button-style;
		}
		
		&__errorButton {
			@include button-style;
			border-color: red !important;
			color: red !important;
		}
		
		&__highlightButton {
			@include button-style;
			//border: 2px solid #154CCB !important;
		}
	}

	&__exampleWrap {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		gap: $spacing8;
	}
	
	&__example {
		display: flex;
		flex: 1;
  		justify-content: flex-start;
  		height: auto;
		padding: 12px;
		margin-bottom: 16px;
		background-color: rgba(21, 76, 202, 0.1);
		border: none;
		border-radius: 10px;
		box-shadow: none;
		align-items: center;
		p {
			// font-size: 15px;
			color: var(--color-prim-800);
		}
		
		&:hover {
			background-color: #D8D8D8 !important;
		}
	}
	
	&__example_mobile {
		display: flex;
		flex: 1;
  		justify-content: flex-start;
  		height: auto;
		padding: 14px 16px;
		margin-left: 44px;
		margin-bottom: 12px;
		background-color: rgba(21, 76, 202, 0.1);
		border: none;
		border-radius: 10px;
		box-shadow: none;
		p {
			font-size: 15px;
			color: var(--color-prim-800);
		}
		
		&:hover {
			background-color: #D8D8D8 !important;
		}
	}
	
	&__iconCommon {
		display: flex;
		align-items: center;
		justify-content: center;
		// margin-top: 8px;
		margin-right: $spacing8;
		width: 32px;
		height: 32px;
		border-radius: 50%;
		object-fit: cover;
		flex: 0 0 32px;
		//font-weight: bold;
		//font-size: 16px;
	}
	
	&__icon {
		@extend .Chat__iconCommon;
		// background-color: #154cca;
	}
	
	&__iconExam {
		@extend .Chat__iconCommon;
		// background: #ffd309;
		background: #d8d8d8;
		margin-right: 8px;
		color: #fff;
		font-weight: 800;
	}
	
	&__input {
		width: 100%;
		min-height: 64px;
		padding: $spacing8 0;
		background: #ffffff;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	
	&__inputmd {
		width: 100%;
		max-width: 960px;
		height: 64px;
		background: #ffffff;
		padding: $spacing8 $spacing16;
		display: flex;
	}
	
	&__inputfield {
		& fieldset {
			top: -10px;
		}
	}

	&__input1 {
		width: 100%;
		max-width: $max-width-web-chat;
		min-height: 48px;
		max-height: 48px;
		// margin: auto;
		// padding: 12px 14px;
		background: #f5f5f5;
		// border: 1px solid #ccc;
		border-radius: 10px;
		display: flex;
		justify-content: space-between;
		align-items: center;

		&:focus-within {
			border: 1px solid var(--color-prim-800);
		}

		&__block {
			@extend .Chat__input1;
			background: #d8d8d8;
		}
	}

	&__input1md {
		@extend .Chat__input1;
		width: calc(100vw - 76px);

		&__block {
			@extend .Chat__input1md;
			background: #d8d8d8;
		}
	}

	&__inputfield1 {
		width: 100%;
		// min-width: 230px;
		max-width: 870px;
		font-size: 16px;
		// font-size < 16px 일 경우, (iOS 기준) input field 클릭 시 화면 자동 확대됨
		font-weight: 400;
		color: #222;
		padding: 15px;
		border: none;
		background: none;
		line-height: 1;

		&:focus-visible {
			outline: none;
			border: none;
			box-shadow: none;
		}
	}

	&__inputButtonWrap {
		width: 70px;
		display: flex;
		justify-content: center;
	}

	&__inputButton {
		width: 18px;
		border: none;
		color: #888;
		font-size: 18px;
		background-color: transparent;
		height: 100%;
		display: flex;
		align-items: center;
		padding: 0;
	}
	
	&__flex {
		width: 100%;
		display: flex;
		justify-content: center;
		
		&__col {
			@extend .Chat__flex;
			flex-direction: column;
		}
	}

	&__chatTopWrap {
		width: 100%;
		height: calc(100vh - 86px);

		&__md {
			@extend .Chat__chatTopWrap;
			height: 100%;
		}

		&__mdDemo {
			@extend .Chat__chatTopWrap;
			height: calc(100% - 77px);
		}
	}
	
	&__container {
		width: 100%;
		max-width: 900px;
		height: 100%;
		padding: 0 24px;
		
		@media (max-width: 600px) {
			padding: 0 16px;
		}
	}
	
	&__box {
		width: 100%;
		min-height: 70px;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;
		border: 1px solid #CCC;
		border-radius: 10px;
		margin: 16px 0;
		
		&__p16 {
			@extend .Chat__box;
			padding: 16px;
		}

		// &:focus-visible {
		// 	border: 2px solid #154cca;
		// }
	}
	
	&__side {
		width: 240px;
		height: 100%;

		@media (max-width: 1080px) {
			width: 0;
		}
	}
	
	&__main {
		width: 100%;
		height: calc(100vh - 86px - 77px);
		// padding: 16px 24px;
		overflow-y: scroll;
		overflow-x: hidden;

		@media screen and (max-width: 600px) {
			height: calc(100vh - 86px);
		}
	}
	
	&__mainmd {
		width: 100%;
		height: 100%;
		overflow-y: scroll;

		&__onboard {
			@extend .Chat__mainmd;
			padding: 0 $spacing16;
		}
	}

	&__greetingWrap {
		width: fit-content;
		height: auto;
		display: flex;
		align-items: flex-start;
		// border: 1px solid #ccc;
		// border-radius: 10px;
		// margin-bottom: 8px;
		// margin: 16px 0;

		&__md {
			@extend .Chat__greetingWrap;
			margin: 0 16px;
		}
	}

	&__greetingContent {
		width: 100%;
		background: $color-gray-200; 
		border-radius: 10px; 
		padding: 12px 14px;
	}

	&__greetingmd {
		width: 100%;
		height: auto;
		display: flex;
		align-items: flex-start;
		// border: 1px solid #ccc;
		// border-radius: 10px;
		// margin-bottom: 8px;
		margin: 16px 0;
	}
	
	&__inlineBtn {
		width: 100%;
		display: flex;
		overflow-x: scroll;
		margin-top: 16px;

		::-webkit-scrollbar {
			display: none;
		}
	}
	
	&__back {
		width: 100%;
		height: 145px;
		flex-shrink: 0;
	}

	&__backmd {
		width: 100%;
		height: 86px;
		flex-shrink: 0;
	}
	
	&__bottom {
		width: 100%;
		min-height: 86px;
		position: absolute;
		bottom: 0;
		background: #ffffff;
		left: 0;
		z-index: 3;
		
		@media screen and (max-width: 960px) {
			padding: 0 $spacing16;
		}
	}
	
	&__bottommd {
		@extend .Chat__bottom;
		left: 0;
		padding: 0;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	
	&__toggleGroup {
		width: 100%;
		text-align: center;
		padding-top: 24px;
		display: flex;
		justify-content: center;
		
		@media (max-width: 1080px) {
			padding-top: 60px;
		}
	}

	&__toggleGroupDemo {
		width: 100%;
		min-height: 45px;
		text-align: center;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	
	&__toggleBack {
		width: 228px;
		height: 45px;
		background: $color-gray-200;
		border-radius: 100px;
		padding: $spacing8;
	}
	
	&__toggle {
		width: 106px;
		height: 29px;
		background: $color-gray-200;
		border: none;
		border-radius: 100px;
		cursor: pointer;
	}

	&__bottombtn {
		width: fit-content;
		min-width: fit-content;
		height: fit-content;
		border: none;
		border-radius: 10px;
		background: #fff;
		color: #222;
		padding: 6px 12px;
		margin-left: 44px;
		margin-bottom: 16px;
		font-size: 14px;
		font-weight: 600;
		text-decoration: none;

		@media screen and (min-width: 600px) {
			font-size: 15px;
		}
	}

	&__footer {
		width: 100%;
		min-height: 22px;
		background: #fff;
		text-align: center;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-start;
		
		a {
			font-weight: 500;
			font-size: 12px;
			line-height: 1;
			color: #999
		}

		&__md {
			@extend .Chat__footer;
			min-height: 22px;
		}
	}

	&__infoModal {
		position: absolute;
		top: 0px;
		left: -210px;
		width: 200px;
		height: 150px;
		background: #d8d8d8;
		border-radius: 10px;
		padding: 8px 16px;
		color: #777;
		font-size: 14px;
		text-align: center;

		&::after {
			content: '';
			position: absolute;
			top: 16px;
			right: -22px;
			width: 0;
			height: 0;
			border-bottom: 6px solid transparent;
			border-top: 6px solid transparent;
			border-left: 12px solid #d8d8d8;
			border-right: 12px solid transparent;

			@media screen and (max-width: 1400px) {
			left: -22px;

			border-bottom: 6px solid transparent;
			border-top: 6px solid transparent;
			border-left: 12px solid transparent;
			border-right: 12px solid #d8d8d8;
		}
		}

		@media screen and (max-width: 1400px) {
			left: 40px;
		}
	}

	&__modalTail {
		position: absolute;
		right: -16px;
		width: 0;
		height: 0;
		border-bottom: 8px solid transparent;
		border-top: 8px solid #eee;
		border-left: 8px solid #eee;
		border-right: 8px solid transparent;
	}

	&__skeletonWrap {
		width: 100%;
		height: 16px;
		display: flex;
		background-color: $color-gray-300;
		border-radius: 99px;
		overflow: hidden;
		position: relative;
	}

	&__skeleton {
		position: absolute;
		width: 50%;
		height: 100%;
		min-width: 400px;
		min-height: 400px;
		background: linear-gradient(90deg, rgba(192, 192, 192, 0) 0%, rgba(192, 192, 192, 1) 48%, rgba(192, 192, 192, 0) 100%);

		@include animate(moveRight, 2.1s, linear, infinite);

	}


	&__progressWrap {
		// width: calc(100vw - 72px);
		width: 100%;
		max-width: $max-width-web-chat - 72px;
		height: 16px;
		background-color: $color-gray-200;
		border-radius: 10px;
		overflow: hidden;

		&__md {
			@extend .Chat__progressWrap;
			width: calc(100vw - 72px - 32px);
		}
	}

	&__progress {
		width: 600px;
		height: 400px;
		background: linear-gradient(to right, rgba(225, 225, 225, 0) 0%, rgba(225, 225, 225, 1) 30%, rgba(225, 225, 225, 0) 100%);
		border-radius: 10px;

		@include animate(moveRight, 2s, ease-in-out, infinite);

		@media screen and (max-width: 960px) {
			width: 400px;
		}

		@media screen and (max-width: 600px) {
			width: 200px;
		}

		&__md {
			@extend .Chat__progress;
			width: 200px;
		}
	}

	// &__progress {
	// 	width: 100%;
	// 	height: 10px;
	// 	background: linear-gradient(to right, );
	// }

	&__progressTextWrap {
		// height: 62px;
		display: flex;
		justify-content: center;
	}

	&__progressText{
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		padding-left: 16px;

		span {
			font-size: 8px;
			color: #bbb;
			position: relative;
			@include animate(textUp, 1.2s, linear, infinite);
		}
		
		span:nth-of-type(1) {
			animation-delay: 0s;
		}

		span:nth-of-type(2) {
			animation-delay: 0.2s;
		}

		span:nth-of-type(3) {
			animation-delay: 0.4s;
		}

		span:nth-of-type(4) {
			animation-delay: 0.6s;
		}

		span:nth-of-type(5) {
			animation-delay: 0.8s;
		}
	}
}

.Sidebar {
	width: 240px;
	height: 100%;
	position: relative;
	top: 0;
	left: 0;
	background-color: #202023;
	z-index: 9999;
	
	&__productList {
		position: relative;
		top: 12px;
		height: calc(100vh - 86px);
		background-color: transparent !important;
		
		&__product {
			position: relative;
			left: 8px;
			right: 8px;
			//bottom: 0.75rem;
			width: calc(100% - 16px);
			max-width: 218px;
			min-height: 40px !important;
			padding: 8px !important;
			border-radius: 6px !important;
			background-color: transparent !important;
			color: #DDDDDD !important;
			box-shadow: none !important;
			
			justify-content: flex-start !important;
			align-items: center !important;
			text-align: left;
			font-size: 14px !important;
			white-space: nowrap;
  			overflow: hidden;
  			text-overflow: ellipsis !important;
			
			&::after {
				content: "";
				position: absolute;
				width: 48px;
				height: 100%;
				right: 0;
				top: 0;
				background: linear-gradient(to right, transparent, #202023 90%);
			}
			
			&:hover {
				background-color: #3B3B3B !important;
			}
		}
	}
	
	&__button {
		left: 8px;
		right: 8px;
		//bottom: 0.75rem;
		width: calc(100% - 16px);
		height: 36px;
		padding: 8px !important;
		border-radius: 6px !important;
		background-color: transparent !important;
		color: #FFFFFF !important;
		box-shadow: none !important;
		
		justify-content: flex-start !important;
		align-items: center !important;
		text-align: left;
		font-size: 14px !important;		
		
		&:hover {
			background-color: #3B3B3B !important;
		}
	}
}

.Topbar {
	width: 100%;
	height: 48px;
	position: absolute;
	top: 0;
	left: 0;
	background-color: #34343f;
	z-index: 9999;
	
	&__button {
		top: 8px;
		right: 8px;
		height: 32px;
		border-radius: 4px !important;
		background-color: transparent !important;
		color: #CCCCCC !important;
		//padding: 0.5;
		box-shadow: none !important;
		font-size: 14px !important;
		
		&:hover {
			color: #FFFFFF !important;
		}
	}
}

.Modal {
	
	&__hyperClova {
		position: absolute;
		bottom: 24px !important;
		left: 24px !important;
		width: 160px;
		height: auto;
		background-color: #F5F5F5 !important;
		border: none !important;
	}
	
	&__button {
		position: absolute;
		bottom: 24px !important;
		right: 24px !important;
		padding: 8px;
		font-size: 12px !important;
		background-color: white !important;
		border: none !important;
	}
}

.Util {
	&__bold {
		font-weight: 600;
	}

	&__textStart {
		text-align: start;
	}

	&__w100mw {
		width: 100%;
		max-width: $max-width-web-chat;
	}
}

.chatContainer {
	display: flex;
	flex-direction: column;
	height: calc(var(--vh, 1vh) * 100);
	justify-content: flex-end;
}

.messageList {
	flex: 1;
	overflow-y: auto;
	padding-bottom: 80px;
}

.inputContainer {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: white;
	padding: 10px;
	box-shadow: 0 -1px 6px rgba(0, 0, 0, 0.1);
	z-index: 1000;
}
/* css */
.Test {
	&__wrapper {
	  position: relative;
	  width: 100%;
	  height: 200px;
	  overflow-y: auto;
	}
	
	&__content {
	  width: 100%;
	  height: auto;
	}
	
	&__makeScrollable {
	  position: absolute;
	  left: 0;
	  width: 1px;
	  height: calc(100% + 1px); // height를 100%보다 1px높게 잡아 실제로 scroll이 되도록 만듭니다.
	}
}