.RecommendSection {
    &__Container {
        width: 100%; 
        height: fit-content; 
        background: #fff;
    }

    &__TopWrap {
        width: 100%;
        height: 132px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 24px;
        padding-bottom: 12px;

        @media screen and (max-width: 600px) {
            height: 60px;
            padding: 12px 16px;
        }

        &__buffer {
            width: 79px;
            height: 18px;

            @media screen and (max-width: 600px) {
                display: none;
            }
        }

        &__img {
            width: 31px;
            height: 36px;

            @media screen and (max-width: 600px) {
                width: 21px;
                height: 24px;
            }
        }

        &__title {
            font-size: 30px;
            font-weight: 700;
            line-height: 120%;
            color: #222;

            @media screen and (max-width: 600px) {
               font-size: 20px;
            }
        }
    }

    &__CarouselContainer {
        width: 100%;
        overflow-x: hidden;
    }

    &__CarouselWrap {
        width: 100%;
        overflow-x: scroll;
        padding: 12px 24px;
        display: flex;
        gap: 8px;

        @media screen and (max-width: 600px) {
            padding: 12px 16px;
        }

        &__card {
            min-width: 274px;
            height: 156px;
            padding: 8px;
            background: #fff;
            border-radius: 10px;
            border: 1px solid #e1e1e1;
            box-shadow: 0 4px 12px 0 rgba(53, 81, 135, 0.08);
            display: flex;
        }
    }
}