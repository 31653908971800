@import './global.scss';
@import './guideVar.scss';

.GuideButtonGroup {
    &__wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: flex-end;
        padding: 0 16px;
    }

    &__button {
        width: fit-content;
        background-color: var(--color-prim-100);
        padding: 12px 14px;
        border-radius: 10px;
        cursor: pointer;

        &:hover {
            background-color: var(--color-prim-200);
        }

        p {
            color: var(--color-prim-800);
        }
    }
}

.TailBubble {
    &__wrapper {
        width: 100%;
        display: flex;
        padding: 0px 16px;
    }

    &__buttonWrapper {
        width: 100%;
        display: flex;
        padding: 8px 16px;
    }

    &__button {
        width: fit-content;
        height: fit-content;
        border: none;
        background-color: var(--color-prim-800);
        padding: 16px;
        border-radius: 10px;
        cursor: pointer;

        p {
            color: $color-white;
        }
    }
}