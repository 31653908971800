//
$max-width-web-chat: 960px;

// spacing variables
$spacing2: 2px;
$spacing4: 4px;
$spacing6: 6px;
$spacing8: 8px;
$spacing12: 12px;
$spacing16: 16px;
$spacing20: 20px;
$spacing24: 24px;
$spacing32: 32px;
$spacing40: 40px;
$spacing48: 48px;
$spacing56: 56px;
$spacing72: 72px;
$spacing80: 80px;

// color variables
:root {
    --color-prim-800: #154cca;
    --color-prim-200: #bbc8e5;
    --color-prim-100: #e0e6f3;
}
$color-prim-800: #154cca;
$color-prim-900: #103b9c;
$color-prim-700: #0025E4;
$color-prim-300: #AAB8ED;
$color-prim-200: #bbc8e5;
$color-prim-150: #E0E5F8;
$color-prim-100: #e0e6f3;
$color-bg-100: #f7f7f7;
$color-gray-900: #000000;
$color-gray-800: #222222;
$color-gray-700: #444444;
$color-gray-600: #666666;
$color-gray-500: #999999;
$color-gray-400: #c0c0c0;
$color-gray-300: #e1e1e1;
$color-gray-200: #efefef;
$color-gray-100: #f6f6f6;
$color-white: #ffffff;
$color-positive: #0cbd48;
$color-warning: #ff5555;
$color-review: #ffc738;
$color-emphasis: #ec3e48;
$color-gradient: #154cca, #00e1e1;

//font-weight
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-semibold: 600;
$font-weight-bold: 700;