:root {
  --color-prim-800: #154cca;
  --color-prim-200: #bbc8e5;
  --color-prim-100: #e0e6f3;
}

@keyframes moveRight {
  0% {
    margin-left: -120%;
  }
  100% {
    margin-left: 120%;
  }
}
.Message {
  display: flex;
  align-items: flex-start;
}
.Message__list, .Message__list__md, .Message__list__highlight {
  display: flex;
  align-items: flex-start;
}
.Message__list__highlight {
  border: 2.5px solid var(--color-prim-800);
}
.Message__list__md {
  margin: 0 16px;
}
.Message__listmy, .Message__listmy__md {
  display: flex;
  justify-content: flex-end;
}
.Message__listmy__md {
  margin: 0 16px;
}
.Message__button {
  height: 32px;
  border-radius: 32px !important;
  font-size: 16px !important;
  box-shadow: none !important;
  margin-top: 4px !important;
  background-color: #FFFFFF;
}
.Message__iconCommon, .Message__myIconExp, .Message__myIcon, .Message__iconExp, .Message__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  min-width: 32px;
  min-height: 32px;
  border-radius: 50%;
  color: white;
  -o-object-fit: cover;
     object-fit: cover;
}
.Message__iconExp {
  background-color: #bb86fc;
}
.Message__myIcon {
  background-color: rgba(21, 76, 203, 0.35);
}
.Message__myIconExp {
  background-color: #e2dfec;
}
.Message__content, .Message__content__my {
  width: 100%;
  background: #efefef;
  border-radius: 10px;
  padding: 12px 14px;
}
.Message__content__my {
  width: -moz-fit-content;
  width: fit-content;
  background: #666666;
}
.Message__text, .Message__text__my {
  width: 100%;
  color: #222;
  word-wrap: break-word;
  white-space: pre-wrap;
}
.Message__text__my {
  color: #ffffff;
}
.Message__bulletList {
  margin-left: 20px;
  text-indent: -20px;
  margin-bottom: 0;
}
@media screen and (max-width: 600px) {
  .Message__bulletList {
    margin-bottom: 8px;
  }
}
.Message__text a, .Message__text__my a {
  color: var(--color-prim-800);
  text-decoration: underline;
  word-break: break-all;
}
.Message__my__text {
  padding: 8px;
  border-radius: 8px !important;
  background-color: transparent;
  color: #4D4D4D;
  text-align: left;
  word-wrap: break-word;
  white-space: pre-wrap;
}
.Message__btnGroup {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0 16px 8px 44px;
  overflow-x: scroll;
}
.Message__actionBtn {
  width: -moz-fit-content;
  width: fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border: none;
  border-radius: 10px;
  background: #fff;
  color: #222;
  padding: 6px 12px;
  margin: 0 4px 0 0;
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
}
@media screen and (min-width: 600px) {
  .Message__actionBtn {
    font-size: 15px;
  }
}
.Message__guideWrapper {
  background: rgb(21, 76, 202);
  border: 1px solid rgb(21, 76, 202);
  border-radius: 10px;
  color: #fff;
  padding: 16px;
  margin-left: 40px;
  text-align: center;
  margin-bottom: 16px;
  display: flex;
}
@media screen and (max-width: 768px) {
  .Message__guideWrapper {
    flex-direction: column;
  }
}
.Message__contentWrapper {
  width: 75%;
  padding-left: 16px;
  text-align: start;
}
@media screen and (max-width: 768px) {
  .Message__contentWrapper {
    width: 100%;
    padding: 0;
  }
}
.Message__linkWrapper {
  width: 25%;
  margin-bottom: 8px;
  font-size: 20px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
@media screen and (max-width: 768px) {
  .Message__linkWrapper {
    width: 100%;
    font-size: 15px;
  }
}
.Message__link {
  width: 90%;
  height: -moz-fit-content;
  height: fit-content;
  padding: 8px 0;
  background: #66b3f7;
  border-radius: 10px;
  text-decoration: none;
  color: #fff;
}
@media screen and (max-width: 768px) {
  .Message__link {
    width: 100%;
    margin-top: 8px;
  }
}
.Message__headline {
  margin: 8px 0;
  margin-bottom: 16px;
  font-size: 20px;
  font-weight: 500;
  color: #fff;
}
@media screen and (max-width: 768px) {
  .Message__headline {
    font-size: 15px;
    word-break: keep-all;
    margin-bottom: 0;
  }
}
.Message__footer {
  margin: 8px 0;
  font-size: 15px;
  font-weight: 500;
  color: #ccc;
}
@media screen and (max-width: 768px) {
  .Message__footer {
    font-size: 12px;
  }
}
.Message__examBtnWrap {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 8px;
}
.Message__examBtn {
  width: -moz-fit-content;
  width: fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border: none;
  border-radius: 10px;
  background: #fff;
  color: #ff972e;
  padding: 4px 8px;
  margin: 0 0 4px 0;
  font-size: 12px;
  font-weight: 600;
  text-decoration: none;
  word-wrap: normal;
}
.Message__examBtn * {
  text-align: left;
}
@media screen and (min-width: 600px) {
  .Message__examBtn {
    margin: 0 4px 0 0;
    padding: 16px;
  }
}
.Message__blur {
  filter: blur(5px);
}

.Carousel__container, .Carousel__container__md {
  width: 100%;
  padding-left: 40px;
  overflow-x: scroll;
  overflow-y: hidden;
}
.Carousel__container::-webkit-scrollbar, .Carousel__container__md::-webkit-scrollbar {
  height: 0;
}
.Carousel__container__md {
  padding-left: 56px;
  padding-right: 8px;
}
.Carousel__flexBox {
  --width: fit-content;
  --height: 100%;
  --justify-content: center;
  --align-items: center;
  --flex-direction: row;
  width: var(--width);
  height: var(--height);
  display: flex;
  flex-direction: var(--flex-direction);
  justify-content: var(--justify-content);
  align-items: var(--align-items);
  margin: 0;
  padding: 0;
}
.Carousel__wrap {
  --point-color: $color-prim-700;
  width: 166px;
  height: 315px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 1px 2px 2px #d8d8d8;
  border: 1px solid var(--point-color);
  overflow: hidden;
  padding: 8px;
  margin-right: 8px;
}
.Carousel__imgWrap {
  --width: 100%;
  --height: 148px;
  width: var(--width);
  height: var(--height);
  padding: 0;
  display: flex;
  justify-content: center;
  border-radius: 8px;
  overflow: hidden;
}
.Carousel__Img {
  --background-image: #efefef;
  --width: 100%;
  --height: 148px;
  width: var(--width);
  height: var(--height);
  background: var(--background-image);
  background-size: cover;
  background-position: center;
}
.Carousel__topWrap {
  width: 100%;
  height: 150px;
}
.Carousel__contentWrap {
  --height: 120px;
  width: 100%;
  height: var(--height);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.Carousel__label {
  --point-color: #ff5833;
  width: 60px;
  font-size: 10px;
  color: var(--point-color);
  border: 1px solid var(--point-color);
  border-radius: 10px;
  text-align: center;
  padding: 0 4px;
}
.Carousel__contents {
  --font-size: 16px;
  --font-weight: 600;
  --margin: 0;
  --line-clamp: 2;
  font-size: var(--font-size);
  font-weight: var(--font-weight);
  color: #222;
  margin: var(--margin);
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: var(--line-clamp);
  -webkit-box-orient: vertical;
}
.Carousel__bottomWrap {
  width: 100%;
  overflow: hidden;
}
.Carousel__desc {
  width: 100%;
  min-height: 36px;
  color: #222;
  font-weight: 600;
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.Carousel__sBtn {
  --point-color: #ff5833;
  width: 100%;
  min-width: -moz-fit-content;
  min-width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border: none;
  border-radius: 10px;
  background: var(--point-color);
  color: #fff;
  padding: 4px 12px;
  margin: 16px 0 8px 0;
  font-size: 12px;
  font-weight: 600;
  text-decoration: none;
}
.Carousel__fbBtn {
  width: 30px;
  height: 30px;
  border: 1px solid #e1e1e1;
  border-radius: 50%;
  background: #f6f6f6;
  position: relative;
  top: 114px;
  left: 114px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Carousel__secondary {
  width: 100%;
  min-height: 38px;
  background: #ffffff;
  border: 1px solid #e1e1e1;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.Carousel__sizeIcon {
  min-width: 16px;
  max-width: 16px;
  min-height: 16px;
  max-height: 16px;
  border-radius: 50%;
  background: #ddd;
  font-size: 12px;
  font-weight: 900;
  text-align: center;
  color: #fff;
  margin-right: 4px;
}
.Carousel__feedbackModal {
  position: absolute;
  top: 0;
  width: 166px;
  height: 315px;
  border-radius: 10px;
  background: #fff;
  display: flex;
  flex-direction: column;
  padding: 32px 8px;
  justify-content: space-around;
  z-index: 5;
}
.Carousel__feedbackModal p {
  text-align: left;
  padding: 0 4px;
  font-weight: 800;
  color: var(--color-prim-800);
}
.Carousel__feedbackModal button {
  border: none;
  border-radius: 10px;
  background: #E8EEF8;
  color: var(--color-prim-800);
  box-shadow: 1px 1px 1px #ccc;
  text-align: left;
  font-size: 14px;
  padding: 4px 16px;
}
.Carousel__Hori__flexBox {
  --width: fit-content;
  --height: 100%;
  --justify-content: center;
  --align-items: center;
  --flex-direction: row;
  width: var(--width);
  height: var(--height);
  display: flex;
  flex-direction: var(--flex-direction);
  justify-content: var(--justify-content);
  align-items: var(--align-items);
  margin: 0;
  padding: 0;
  gap: 8px;
}
.Carousel__Hori__wrap {
  min-width: 274px;
  height: 156px;
  background: #ffffff;
  border: 1px solid #e1e1e1;
  border-radius: 10px;
  border: 1px solid var(--point-color);
}
.Carousel__Hori__topWrap {
  width: 100%;
  height: 156px;
  padding: 8px;
  display: flex;
}
.Carousel__Hori__imgWrap {
  --minWidth: 105px;
  --minHeight: 100%;
  min-width: 105px;
  min-height: var(--minHeight);
  padding: 0;
  display: flex;
  justify-content: center;
  border-radius: 8px;
  overflow: hidden;
}
.Carousel__Hori__Img {
  --background-image: #efefef;
  --minWidth: 100%;
  --minHeight: 100%;
  min-width: var(--minWidth);
  min-height: var(--minHeight);
  background: var(--background-image);
  background-size: cover;
  background-position: center;
}
.Carousel__Hori__fbBtn {
  width: 30px;
  height: 30px;
  border: 1px solid #e1e1e1;
  border-radius: 50%;
  background: #f6f6f6;
  position: relative;
  top: 105px;
  left: 74px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.NavCarousel__Container {
  width: 354px;
  height: 156px;
  overflow: hidden;
  padding-left: 32px;
}
.NavCarousel__CardWrap {
  width: 966px;
  height: 100%;
  position: absolute;
  transform: translateX(0);
  display: flex;
  transition: transform 0.7s;
}
.NavCarousel__Card {
  min-width: 274px;
  margin: 0 24px;
  height: 156px;
  background: #ffffff;
  border: 1px solid #e1e1e1;
  border-radius: 10px;
  box-shadow: 0 -4px 12px 0 rgba(53, 81, 135, 0.08);
}
.NavCarousel__VivinoWrap {
  position: relative;
  top: 105px;
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background: #ffffff;
  border-radius: 10px;
  padding: 4px;
  display: flex;
  flex-direction: column;
}

.Skeleton, .Skeleton__Bar {
  background: linear-gradient(to right, rgba(225, 225, 225, 0) 0%, rgb(225, 225, 225) 30%, rgba(225, 225, 225, 0) 100%);
  animation: moveRight 2s ease-in-out infinite;
}
.Skeleton__BarWrap {
  --width: 150px;
  --height: 16px;
  width: var(--width);
  height: var(--height);
  background-color: #f6f6f6;
  border-radius: 10px;
  overflow: hidden;
}
.Skeleton__Bar {
  --width: 747px;
  --height: 150px;
  width: var(--width);
  height: var(--height);
}
.Skeleton__Img {
  --background-image: linear-gradient(to right, rgba(225, 225, 225, 0) 0%, rgba(225, 225, 225, 1) 30%, rgba(225, 225, 225, 0) 100%);
  --width: 1400px;
  --height: 148px;
  width: var(--width);
  height: var(--height);
  background: var(--background-image);
  animation: moveRight 2s ease-in-out infinite;
}/*# sourceMappingURL=Message.module.css.map */