.RecommendSection__Container {
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  background: #fff;
}
.RecommendSection__TopWrap {
  width: 100%;
  height: 132px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
  padding-bottom: 12px;
}
@media screen and (max-width: 600px) {
  .RecommendSection__TopWrap {
    height: 60px;
    padding: 12px 16px;
  }
}
.RecommendSection__TopWrap__buffer {
  width: 79px;
  height: 18px;
}
@media screen and (max-width: 600px) {
  .RecommendSection__TopWrap__buffer {
    display: none;
  }
}
.RecommendSection__TopWrap__img {
  width: 31px;
  height: 36px;
}
@media screen and (max-width: 600px) {
  .RecommendSection__TopWrap__img {
    width: 21px;
    height: 24px;
  }
}
.RecommendSection__TopWrap__title {
  font-size: 30px;
  font-weight: 700;
  line-height: 120%;
  color: #222;
}
@media screen and (max-width: 600px) {
  .RecommendSection__TopWrap__title {
    font-size: 20px;
  }
}
.RecommendSection__CarouselContainer {
  width: 100%;
  overflow-x: hidden;
}
.RecommendSection__CarouselWrap {
  width: 100%;
  overflow-x: scroll;
  padding: 12px 24px;
  display: flex;
  gap: 8px;
}
@media screen and (max-width: 600px) {
  .RecommendSection__CarouselWrap {
    padding: 12px 16px;
  }
}
.RecommendSection__CarouselWrap__card {
  min-width: 274px;
  height: 156px;
  padding: 8px;
  background: #fff;
  border-radius: 10px;
  border: 1px solid #e1e1e1;
  box-shadow: 0 4px 12px 0 rgba(53, 81, 135, 0.08);
  display: flex;
}/*# sourceMappingURL=RecommendSection.module.css.map */