@import '../styles/guideVar.scss';


@mixin animate($animation,$duration,$method,$times){
    animation: $animation $duration $method $times;
}

@mixin keyframes($name){
    @keyframes #{$name}{
        @content;
    }
}

@include keyframes(moveRight) {
	0%{
		margin-left: -120%;
	}
	100% {
		margin-left: 120%;
	}
}

.Message {
	display: flex;
	align-items: flex-start;

	&__list {
		display: flex;
		align-items: flex-start;

		&__highlight {
			@extend .Message__list;
			border: 2.5px solid var(--color-prim-800);
		}

		&__md {
			@extend .Message__list;
			margin: 0 $spacing16;
		}
	}

	&__listmy {
		display: flex;
		justify-content: flex-end;

		&__md {
			@extend .Message__listmy;
			margin: 0 $spacing16;
		}
	}
	
	&__button {
		height: 32px;
		border-radius: 32px !important;
		font-size: 16px !important;
		box-shadow: none !important;
		margin-top: 4px !important;
		background-color: #FFFFFF;
	}

	&__iconCommon {
		display: flex;
		align-items: center;
		justify-content: center;
		// margin-top: 2px;
		margin-right: $spacing8;
		min-width: 32px;
		min-height: 32px;
		border-radius: 50%;
		color: white;
		object-fit: cover;
		//font-weight: bold;
		//font-size: 16px;
	}
	
	&__icon {
		@extend .Message__iconCommon;
		// background-color: var(--color-prim-800);
	}
	
	&__iconExp {
		@extend .Message__iconCommon;
		background-color: #bb86fc;
	}

	&__myIcon {
		@extend .Message__iconCommon;
		background-color: rgba(21, 76, 203, 0.35);
	}
	
	&__myIconExp {
		@extend .Message__iconCommon;
		background-color: #e2dfec;
	}

	&__content {
		width: 100%;
		background: $color-gray-200;
		border-radius: 10px;
		padding: 12px 14px;

		&__my {
			@extend .Message__content;
			width: fit-content;
			background: $color-gray-600;
		}
	}

	&__text {
		width: 100%;
		color: #222;
		word-wrap: break-word;
		white-space: pre-wrap;

		&__my {
			@extend .Message__text;
			color: $color-white;
		}
	}

	&__bulletList {
		margin-left: 20px;
		text-indent: -20px;
		margin-bottom: 0;
		
		@media screen and (max-width: 600px) {
			margin-bottom: 8px;
		}
	}
	
	&__text a {
		color: var(--color-prim-800);
		text-decoration: underline;
		word-break: break-all;
	}

	&__my {
		&__text {
			padding: 8px;
			border-radius: 8px !important;
			background-color: transparent;
			color: #4D4D4D;
			text-align: left;
			word-wrap: break-word;
			white-space: pre-wrap;
		}
	}

	&__btnGroup {
		width: 100%;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;
		padding: 0 16px 8px 44px;
		overflow-x: scroll;
	}

	&__actionBtn {
		width: fit-content;
		min-width: fit-content;
		height: fit-content;
		border: none;
		border-radius: 10px;
		background: #fff;
		color: #222;
		padding: 6px 12px;
		margin: 0 4px 0 0;
		font-size: 14px;
		font-weight: 600;
		text-decoration: none;

		@media screen and (min-width: 600px) {
			font-size: 15px;
		}
	}
	
	&__guideWrapper {
		// width: 100%;
		background: rgba(21,76,202, 1);
		border: 1px solid rgb(21,76,202);
		border-radius: 10px;
		color: #fff;
		padding: 16px;
		margin-left: 40px;
		text-align: center;
		// box-shadow: 3px 3px 5px #555;
		margin-bottom: 16px;
		display: flex;

		@media screen and (max-width: 768px) {
			flex-direction: column;
		}
	}

	&__contentWrapper {
		width: 75%;
		padding-left: 16px;
		text-align: start;

		@media screen and (max-width: 768px) {
			width: 100%;
			padding: 0;
		}
	}
	
	&__linkWrapper {
		width: 25%;
		margin-bottom: 8px;
		font-size: 20px;
		font-weight: 700;
		display: flex;
		justify-content: center;
		align-items: flex-end;

		@media screen and (max-width: 768px) {
			width: 100%;
			font-size: 15px;
		}
	}
	
	&__link {
		width: 90%;
		height: fit-content;
		padding: 8px 0;
		background: #66b3f7;
		border-radius: 10px;
		text-decoration: none;
		color: #fff;

		@media screen and (max-width: 768px) {
			width: 100%;
			margin-top: 8px;
		}
	}
	
	&__headline {
		margin: 8px 0;
		margin-bottom: 16px;
		font-size: 20px;
		font-weight: 500;
		color: #fff;

		@media screen and (max-width: 768px) {
			font-size: 15px;
			word-break: keep-all;
			margin-bottom: 0;
		}
	}
	
	&__footer {
		margin: 8px 0;
		font-size: 15px;
		font-weight: 500;
		color: #ccc;

		@media screen and (max-width: 768px) {
			font-size: 12px;
		}
	}

	&__examBtnWrap {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		gap: $spacing8;
	}

	&__examBtn {
		width: fit-content;
		min-width: fit-content;
		height: fit-content;
		border: none; 
		border-radius: 10px;
		background: #fff;
		color: #ff972e;
		padding: $spacing4 $spacing8;
		margin: 0 0 4px 0;
		font-size: 12px;
		font-weight: 600;
		text-decoration: none;
		word-wrap: normal;

		* {
			text-align: left;
		}

		@media screen and (min-width: 600px) {
			margin: 0 4px 0 0;
			padding: $spacing16;
		}
	}

	&__blur {
		filter: blur(5px);
	}
}

.Carousel {
	&__container {
		width: 100%;
		padding-left: 40px;
		overflow-x: scroll;
		overflow-y: hidden;

		&::-webkit-scrollbar {
			height: 0;
		}

		&__md {
			@extend .Carousel__container;
			padding-left: $spacing56;
			padding-right: $spacing8;
		}
	}

	&__flexBox {
		--width: fit-content;
		--height: 100%;
		--justify-content: center;
		--align-items: center;
		--flex-direction: row;
		width: var(--width);
		height: var(--height);
		display: flex;
		flex-direction: var(--flex-direction);
		justify-content: var(--justify-content);
		align-items: var(--align-items);
		margin: 0;
		padding: 0;
	}

	&__wrap {
		--point-color: $color-prim-700;
		width: 166px;
		height: 315px;
		background: #fff;
		border-radius: 10px;
		box-shadow: 1px 2px 2px #d8d8d8;
		border: 1px solid var(--point-color);
		overflow: hidden;
		padding: $spacing8;
		margin-right: $spacing8;
	}

	&__imgWrap {
		--width: 100%;
		--height: 148px;
		width: var(--width);
		height: var(--height);
		padding: 0;
		display: flex;
		justify-content: center;
		border-radius: 8px;
		overflow: hidden;
	}

	&__Img {
		--background-image: #efefef;
		--width: 100%;
		--height: 148px;
		width: var(--width);
		height: var(--height);
		background: var(--background-image);
		background-size: cover;
		background-position: center;
	}

	&__topWrap {
		width: 100%;
		height: 150px;
	}

	&__contentWrap {
		--height: 120px;
		width: 100%;
		height: var(--height);
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	&__label {
		--point-color: #ff5833;
		width: 60px; 
		font-size: 10px;
		color: var(--point-color);
		border: 1px solid var(--point-color);
		border-radius: 10px;
		text-align: center;
		padding: 0 4px;
	}

	&__contents {
		--font-size: 16px;
		--font-weight: 600;
		--margin: 0;
		--line-clamp: 2;
		font-size: var(--font-size);
		font-weight: var(--font-weight);
		color: #222;
		margin: var(--margin);
		overflow: hidden;
		text-overflow: ellipsis;
		word-wrap: break-word;
    
		display: -webkit-box;
		-webkit-line-clamp: var(--line-clamp); // 원하는 라인수
		-webkit-box-orient: vertical
	}

	&__bottomWrap {
		width: 100%;
		overflow: hidden;
	}

	&__desc {
		width: 100%;
		min-height: 36px;
		color: #222;
		font-weight: 600;
		font-size: 12px;
		overflow: hidden;
		text-overflow: ellipsis;
		word-break: break-word;
    
		display: -webkit-box;
		-webkit-line-clamp: 2; // 원하는 라인수
		-webkit-box-orient: vertical
	}

	&__sBtn {
		--point-color: #ff5833;
		width: 100%;
		min-width: fit-content;
		height: fit-content;
		border: none; 
		border-radius: 10px;
		background: var(--point-color);
		color: #fff;
		padding: 4px 12px;
		margin: 16px 0 8px 0;
		font-size: 12px;
		font-weight: 600;
		text-decoration: none;
	}

	&__fbBtn {
		width: 30px;
		height: 30px;
		border: 1px solid $color-gray-300;
		border-radius: 50%;
		background: $color-gray-100;
		position: relative;
		top: 114px;
		left: 114px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&__secondary {
		width: 100%;
		min-height: 38px;
		background: $color-white;
		border: 1px solid $color-gray-300;
		border-radius: 10px;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
	}

	&__sizeIcon {
		min-width: 16px;
		max-width: 16px;
		min-height: 16px;
		max-height: 16px;
		border-radius: 50%;
		background: #ddd;
		font-size: 12px;
		font-weight: 900;
		text-align: center;
		color: #fff;
		margin-right: 4px;
	}

	&__feedbackModal {
		position: absolute;
		top: 0;
		width: 166px;
		height: 315px;
		border-radius: 10px;
		background: #fff;
		display: flex;
		flex-direction: column;
		padding: 32px 8px;
		justify-content: space-around;
		z-index: 5;
		
		p {
			text-align: left;
			padding: 0 4px;
			font-weight: 800;
			color: var(--color-prim-800);
		}

		button {
			border: none;
			border-radius: 10px;
			background: #E8EEF8;
			// background: var(--color-prim-800);
			// color: #E8EEF8;
			color: var(--color-prim-800);
			box-shadow: 1px 1px 1px #ccc;
			text-align: left;
			font-size: 14px;
			padding: 4px 16px;
		}
	}

	&__Hori {
		&__flexBox {
			--width: fit-content;
			--height: 100%;
			--justify-content: center;
			--align-items: center;
			--flex-direction: row;
			width: var(--width);
			height: var(--height);
			display: flex;
			flex-direction: var(--flex-direction);
			justify-content: var(--justify-content);
			align-items: var(--align-items);
			margin: 0;
			padding: 0;
			gap: $spacing8;
		}

		&__wrap {
			$--point-color: $color-prim-800;
			min-width: 274px;
            height: 156px;
            background: $color-white;
            border: 1px solid $color-gray-300;
            border-radius: 10px;
			border: 1px solid var(--point-color);
		}
		
		&__topWrap {
			width: 100%;
			height: 156px;
			padding: $spacing8;
			display: flex;
			// gap: $spacing8;
		}

		&__imgWrap {
			--minWidth: 105px;
			--minHeight: 100%;
			min-width: 105px;
			min-height: var(--minHeight);
			padding: 0;
			display: flex;
			justify-content: center;
			border-radius: 8px;
			overflow: hidden;
		}

		&__Img {
			--background-image: #efefef;
			--minWidth: 100%;
			--minHeight: 100%;
			min-width: var(--minWidth);
			min-height: var(--minHeight);
			background: var(--background-image);
			
			background-size: cover;
			background-position: center;
		}

		&__fbBtn {
			width: 30px;
			height: 30px;
			border: 1px solid $color-gray-300;
			border-radius: 50%;
			background: $color-gray-100;
			position: relative;
			top: 105px;
			left: 74px;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
}

.NavCarousel {
	&__Container {
		width: 354px;
		height: 156px;
		overflow: hidden;
		padding-left: 32px;
	}

	&__CardWrap {
		width: 966px;
		height: 100%;
		// background: #d8d8d8;
		position: absolute;
		transform: translateX(0);
		display: flex;

		transition: transform 0.7s;
	}

	&__Card {
		$--point-color: $color-prim-800;
		min-width: 274px;
		margin: 0 24px;
		height: 156px;
		background: $color-white;
		border: 1px solid $color-gray-300;
		border-radius: 10px;
		box-shadow: 0 -4px 12px 0 rgba(53, 81, 135, 0.08);
	}

	&__VivinoWrap {
		position: relative;
		top: 105px;
		width: fit-content;
		height: fit-content;
		background: $color-white;
		border-radius: 10px;
		padding: 4px;
		display: flex;
		flex-direction: column;
	}
}

.Skeleton {
	background: linear-gradient(to right, rgba(225, 225, 225, 0) 0%, rgba(225, 225, 225, 1) 30%, rgba(225, 225, 225, 0) 100%);
	@include animate(moveRight, 2s, ease-in-out, infinite);

	&__BarWrap {
		--width: 150px;
		--height: 16px;
		width: var(--width);
		height: var(--height);
		background-color: $color-gray-100;
		border-radius: 10px;
		overflow: hidden;
	}

	&__Bar {
		--width: 747px;
		--height: 150px;
		@extend .Skeleton;
		width: var(--width);
		height: var(--height);
	}

	&__Img {
		--background-image: linear-gradient(to right, rgba(225, 225, 225, 0) 0%, rgba(225, 225, 225, 1) 30%, rgba(225, 225, 225, 0) 100%);
		--width: 1400px;
		--height: 148px;
		width: var(--width);
		height: var(--height);
		background: var(--background-image);
		
		@include animate(moveRight, 2s, ease-in-out, infinite);
	}
}

