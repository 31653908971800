@charset "UTF-8";
@import url(https://cdn.jsdelivr.net/gh/moonspam/NanumSquare@2.0/nanumsquare.css);
:root {
  --color-prim-800: #154cca;
  --color-prim-200: #bbc8e5;
  --color-prim-100: #e0e6f3;
}

@font-face {
  font-family: "pretendard";
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/Pretendard-Light.woff") format("woff");
  /* light */
}
@font-face {
  font-family: "pretendard";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/Pretendard-Regular.woff") format("woff");
  /* regular */
}
@font-face {
  font-family: "pretendard";
  font-style: normal;
  font-weight: 600;
  src: url("../fonts/Pretendard-SemiBold.woff") format("woff");
  /* semi bold */
}
@font-face {
  font-family: "pretendard";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/Pretendard-Bold.woff") format("woff");
  /* regular */
}
html {
  -webkit-text-size-adjust: none;
}

#root {
  width: 100%;
  max-width: 100%;
  --vh: 100%;
  height: 100%;
  overflow-x: hidden;
  -webkit-text-size-adjust: none;
}

body,
html {
  width: 100%;
  max-width: 100%;
  height: var(--vh);
  overflow-y: hidden;
  font-family: "pretendard";
  margin: 0;
  overflow-x: hidden;
  color: #222222;
  -webkit-text-size-adjust: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body *,
html * {
  box-sizing: border-box;
}
body:focus-visible,
html:focus-visible {
  outline: none;
  border: 1px solid var(color-prim-800);
  border-radius: 10px;
  transform: translateZ(0);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

input::-moz-placeholder {
  margin: 0;
  font-family: "pretendard", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
.label,
span,
input,
input::placeholder {
  margin: 0;
  font-family: "pretendard", sans-serif;
}
input::-moz-placeholder:focus-visible {
  outline: none;
  box-shadow: none;
}
h1:focus-visible,
h2:focus-visible,
h3:focus-visible,
h4:focus-visible,
h5:focus-visible,
h6:focus-visible,
p:focus-visible,
.label:focus-visible,
span:focus-visible,
input:focus-visible,
input::placeholder:focus-visible {
  outline: none;
  box-shadow: none;
}

.h1 {
  font-family: "pretendard", sans-serif;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.4px;
  font-weight: 700;
}

.h2 {
  font-family: "pretendard", sans-serif;
  font-size: 15px;
  line-height: 19px;
  letter-spacing: -0.375px;
  font-weight: 700;
}

.h3 {
  font-family: "pretendard", sans-serif;
  font-size: 15px;
  line-height: 19px;
  letter-spacing: -0.375px;
  font-weight: 400;
}

.h4, .ProductCard__productPrice, .ProductCard__discountRate {
  font-family: "pretendard", sans-serif;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.35px;
  font-weight: 700;
}

.h5 {
  font-family: "pretendard", sans-serif;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.35px;
  font-weight: 600;
}

.h6, .ProductCard__productName, .ProductCard__productName__shrink {
  font-family: "pretendard", sans-serif;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.35px;
  font-weight: 400;
}

.h7 {
  font-family: "pretendard", sans-serif;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: -0.325px;
  font-weight: 700;
}

.h8, .ProductCard__detailButtonText, .ProductCard__companyName {
  font-family: "pretendard", sans-serif;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: -0.3px;
  font-weight: 600;
}

.h9, .ProductCard__reviewScore, .ProductCard__productName__shrink {
  font-family: "pretendard", sans-serif;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: -0.3px;
  font-weight: 400;
}

.h10 {
  font-family: "pretendard", sans-serif;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: -0.275px;
  font-weight: 600;
}

.h11 {
  font-family: "pretendard", sans-serif;
  font-size: 10px;
  line-height: 13px;
  letter-spacing: -0.25px;
  font-weight: 600;
}

.badge-font {
  font-family: "pretendard", sans-serif;
  font-size: 10px;
  line-height: 10px;
  font-weight: 600;
}

.vivino-font {
  font-family: "pretendard", sans-serif;
  font-size: 10px;
  line-height: 1.2;
  font-weight: 700;
  color: #aa1329;
}

.sub-title {
  font-family: "pretendard", sans-serif;
  font-size: 18px;
  line-height: 1.2;
  font-weight: 400;
}

.line-clamp-1 {
  width: 100%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  overflow: hidden;
}

.line-clamp-2 {
  width: 100%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  overflow: hidden;
}

button,
a {
  cursor: pointer;
  -webkit-text-fill-color: inherit;
  color: inherit;
}

a {
  text-decoration: none;
}
a:-webkit-any-link {
  color: inherit;
}

ul,
ol {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.style.Chat__inputfield1:focus-visible {
  outline: 0px;
}

:focus-visible {
  outline: none;
  box-shadow: 0 0 0 2px inset var(--color-prim-800);
}

li::marker {
  text-indent: 0px;
  text-align: start;
  -moz-text-align-last: start;
       text-align-last: start;
}

/* width와 height는 스크롤바의 크기를 지정합니다. */
/* thumb-radius는 스크롤바 위아래 버튼의 크기를 지정합니다. */
/* track-color는 스크롤바 바탕 색상을 지정합니다. */
/* thumb-color는 스크롤바 버튼 색상을 지정합니다. */
/* thumb-active-color는 스크롤바 버튼을 클릭할 때 나타나는 색상을 지정합니다. */
::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0);
}

::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0);
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0);
}

::-webkit-scrollbar-thumb:active {
  background-color: rgba(0, 0, 0, 0);
}

.fc-prim-800 {
  color: #154cca;
}

.fc-prim-900 {
  color: #103b9c;
}

.fc-prim-700 {
  color: #0025E4;
}

.fc-prim-300 {
  color: #AAB8ED;
}

.fc-prim-200 {
  color: #bbc8e5;
}

.fc-prim-150 {
  color: #E0E5F8;
}

.fc-prim-100 {
  color: #e0e6f3;
}

.fc-bg-100 {
  color: #f7f7f7;
}

.fc-gray-900 {
  color: #000000;
}

.fc-gray-800, .ProductCard__productPrice, .ProductCard__productName, .ProductCard__productName__shrink {
  color: #222222;
}

.fc-gray-700 {
  color: #444444;
}

.fc-gray-600, .ProductCard__detailButtonText {
  color: #666666;
}

.fc-gray-500, .ProductCard__reviewScore, .ProductCard__companyName {
  color: #999999;
}

.fc-gray-400 {
  color: #c0c0c0;
}

.fc-gray-300 {
  color: #e1e1e1;
}

.fc-gray-200 {
  color: #efefef;
}

.fc-gray-100 {
  color: #f6f6f6;
}

.fc-white {
  color: #ffffff;
}

.fc-positive-900 {
  color: #0cbd48;
}

.fc-positive-300 {
  color: #d6eddc;
}

.fc-warning, .ProductCard__discountRate {
  color: #ff5555;
}

.fc-review {
  color: #ffc738;
}

.fc-emphasis {
  color: #ec3e48;
}

.fc-text {
  color: #222222;
}

.spacing-2 {
  width: 2px;
  min-width: 2px;
  height: 2px;
  min-height: 2px;
}

.spacing-4 {
  width: 4px;
  min-width: 4px;
  height: 4px;
  min-height: 4px;
}

.spacing-6 {
  width: 6px;
  min-width: 6px;
  height: 6px;
  min-height: 6px;
}

.spacing-8 {
  width: 8px;
  min-width: 8px;
  height: 8px;
  min-height: 8px;
}

.spacing-12 {
  width: 12px;
  min-width: 12px;
  height: 12px;
  min-height: 12px;
}

.spacing-16 {
  width: 16px;
  min-width: 16px;
  height: 16px;
  min-height: 16px;
}

.spacing-20 {
  width: 20px;
  min-width: 20px;
  height: 20px;
  min-height: 20px;
}

.spacing-24 {
  width: 24px;
  min-width: 24px;
  height: 24px;
  min-height: 24px;
}

.spacing-32 {
  width: 32px;
  min-width: 32px;
  height: 32px;
  min-height: 32px;
}

.spacing-40 {
  width: 40px;
  min-width: 40px;
  height: 40px;
  min-height: 40px;
}

.spacing-48 {
  width: 48px;
  min-width: 48px;
  height: 48px;
  min-height: 48px;
}

.spacing-56 {
  width: 56px;
  min-width: 56px;
  height: 56px;
  min-height: 56px;
}

.spacing-72 {
  width: 72px;
  min-width: 72px;
  height: 72px;
  min-height: 72px;
}

.spacing-80 {
  width: 80px;
  min-width: 80px;
  height: 80px;
  min-height: 80px;
}

.hide {
  display: none;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.pointer {
  cursor: pointer;
}

.non-clickable {
  cursor: not-allowed !important;
}

:root {
  --color-prim-800: #154cca;
  --color-prim-200: #bbc8e5;
  --color-prim-100: #e0e6f3;
}

:root {
  --carousel-card-width: 100%;
  --carousel-wrap-width: fit-content;
  --carousel-wrap-width-shrink: fit-content;
  --carousel-wrap-height: 150px;
  --carousel-wrap-height-shrink: 50px;
  --carousel-wrap-transform: translateX(0);
}

.CarouselContainer__container {
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  overflow: hidden;
  position: relative;
}
.CarouselContainer__scrollBox, .CarouselContainer__scrollBox__shrink {
  width: var(--carousel-card-width);
  height: var(--carousel-wrap-height);
  overflow-x: scroll;
  transition: transform 0.3s ease-out;
}
.CarouselContainer__scrollBox__shrink {
  height: var(--carousel-wrap-height-shrink);
}
.CarouselContainer__wrap, .CarouselContainer__wrap__shrink {
  width: var(--carousel-wrap-width);
  height: 100%;
  display: flex;
  overflow: hidden;
  transform: var(--carousel-wrap-transform);
}
.CarouselContainer__wrap__shrink {
  width: var(--carousel-wrap-width-shrink);
  height: var(--carousel-wrap-height-shrink);
}
.CarouselContainer__wrap ::-webkit-scrollbar, .CarouselContainer__wrap__shrink ::-webkit-scrollbar {
  display: none;
}

.carousel {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 100%;
  transition: transform 0.3s ease-out;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
}
.carousel:active {
  cursor: grabbing;
}

.ProductCard, .ProductCard__shrink {
  width: 100%;
  height: 100%;
  padding: 8px 16px 4px 16px;
  display: flex;
  transition: transform 0.3s ease-out;
  scroll-snap-align: start;
}
.ProductCard__shrink {
  height: 42px;
  padding: 8px 56px 16px 56px;
}
.ProductCard__productImage, .ProductCard__productImage__shrink {
  width: 132px;
  min-width: 132px;
  height: 132px;
  min-height: 132px;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 8px;
  border: 1px solid #efefef;
}
.ProductCard__productImage__shrink {
  width: 34px;
  height: 34px;
}
.ProductCard__productInfo, .ProductCard__productInfo__shrink {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.ProductCard__productInfo__shrink {
  flex-direction: row;
}
.ProductCard__companyName {
  min-height: 16px;
  height: 16px;
}
.ProductCard__productName, .ProductCard__productName__shrink {
  width: 100%;
  height: 32px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.ProductCard__priceWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.ProductCard__reviewWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.ProductCard__detailButton {
  width: 100%;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e1e1e1;
  border-radius: 10px;
  padding: 0 10px;
  cursor: pointer;
}
.ProductCard__detailButtonText {
  cursor: pointer;
}
.ProductCard__paginationContainer {
  width: 100%;
  height: 22px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 0 12px 0;
}

.ProductShrinkView__carouselCardWrap {
  display: flex;
  width: 100%;
  max-height: 50px;
  justify-content: flex-start;
  padding: 8px 56px;
  padding-bottom: 0;
  gap: 8px;
}
.ProductShrinkView__productCardWrap {
  width: 100%;
  height: 100%;
  padding: 8px 0;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
}
.ProductShrinkView__image {
  width: 34px;
  min-width: 34px;
  height: 34px;
  min-height: 34px;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 8px;
  border: 1px solid #efefef;
}

.ExtraPadding__horizontal {
  padding-left: 56px;
  padding-right: 56px;
}/*# sourceMappingURL=ProductView.module.css.map */