:root {
  --color-prim-800: #154cca;
  --color-prim-200: #bbc8e5;
  --color-prim-100: #e0e6f3;
}

html,
body {
  margin: 0;
  padding: 0;
  background-color: white !important;
  overflow-x: hidden;
  overflow-y: hidden;
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
    transform: translatey(-10px);
  }
  100% {
    opacity: 1;
  }
}
@keyframes fillRight {
  0% {
    width: 0;
  }
  50% {
    width: 50%;
  }
  100% {
    width: 100%;
  }
}
@keyframes textUp {
  0% {
    top: 0;
  }
  20% {
    top: -2px;
  }
  40% {
    top: 0;
  }
  100% {
    top: 0;
  }
}
@keyframes moveRight {
  0%, 14.29% {
    transform: translateX(-100%);
  }
  52.38% {
    transform: translateX(200%);
  }
  100% {
    transform: translateX(200%);
  }
}
.Chat__chatWrap, .Chat__chatWrap__md {
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  height: calc(100vh - 86px);
}
@media screen and (max-width: 960px) {
  .Chat__chatWrap, .Chat__chatWrap__md {
    padding: 0 16px;
  }
}
.Chat__chatWrap__md {
  height: calc(100% - 86px);
  padding: 0;
}
.Chat__chatWrapDlst, .Chat__chatWrapDlst__md {
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  height: calc(100vh - 86px);
}
@media screen and (max-width: 960px) {
  .Chat__chatWrapDlst, .Chat__chatWrapDlst__md {
    padding: 0 16px;
  }
}
.Chat__chatWrapDlst__md {
  height: calc(100% - 86px);
  padding: 0;
}
.Chat__wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.Chat__list {
  width: 100%;
  height: 100%;
  max-width: 960px;
}
.Chat__recommend {
  width: fit-content;
  background-color: #FFFFFF !important;
}
.Chat__recommend__button {
  min-height: 32px;
  background-color: #F5F5F5 !important;
  border: 1px solid #CCC !important;
  border-radius: 32px !important;
  font-size: 14px !important;
  color: black !important;
  white-space: nowrap;
  box-shadow: none !important;
  font-weight: 600;
  text-align: start;
  margin-right: 4px;
}
.Chat__recommend__button:hover {
  background-color: #D8D8D8 !important;
}
.Chat__recommend__errorButton {
  min-height: 32px;
  background-color: #F5F5F5 !important;
  border: 1px solid #CCC !important;
  border-radius: 32px !important;
  font-size: 14px !important;
  color: black !important;
  white-space: nowrap;
  box-shadow: none !important;
  font-weight: 600;
  text-align: start;
  margin-right: 4px;
  border-color: red !important;
  color: red !important;
}
.Chat__recommend__errorButton:hover {
  background-color: #D8D8D8 !important;
}
.Chat__recommend__highlightButton {
  min-height: 32px;
  background-color: #F5F5F5 !important;
  border: 1px solid #CCC !important;
  border-radius: 32px !important;
  font-size: 14px !important;
  color: black !important;
  white-space: nowrap;
  box-shadow: none !important;
  font-weight: 600;
  text-align: start;
  margin-right: 4px;
}
.Chat__recommend__highlightButton:hover {
  background-color: #D8D8D8 !important;
}
.Chat__exampleWrap {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 8px;
}
.Chat__example {
  display: flex;
  flex: 1;
  justify-content: flex-start;
  height: auto;
  padding: 12px;
  margin-bottom: 16px;
  background-color: rgba(21, 76, 202, 0.1);
  border: none;
  border-radius: 10px;
  box-shadow: none;
  align-items: center;
}
.Chat__example p {
  color: var(--color-prim-800);
}
.Chat__example:hover {
  background-color: #D8D8D8 !important;
}
.Chat__example_mobile {
  display: flex;
  flex: 1;
  justify-content: flex-start;
  height: auto;
  padding: 14px 16px;
  margin-left: 44px;
  margin-bottom: 12px;
  background-color: rgba(21, 76, 202, 0.1);
  border: none;
  border-radius: 10px;
  box-shadow: none;
}
.Chat__example_mobile p {
  font-size: 15px;
  color: var(--color-prim-800);
}
.Chat__example_mobile:hover {
  background-color: #D8D8D8 !important;
}
.Chat__iconCommon, .Chat__iconExam, .Chat__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
  flex: 0 0 32px;
}
.Chat__iconExam {
  background: #d8d8d8;
  margin-right: 8px;
  color: #fff;
  font-weight: 800;
}
.Chat__input {
  width: 100%;
  min-height: 64px;
  padding: 8px 0;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Chat__inputmd {
  width: 100%;
  max-width: 960px;
  height: 64px;
  background: #ffffff;
  padding: 8px 16px;
  display: flex;
}
.Chat__inputfield fieldset {
  top: -10px;
}
.Chat__input1, .Chat__input1md, .Chat__input1md__block, .Chat__input1__block {
  width: 100%;
  max-width: 960px;
  min-height: 48px;
  max-height: 48px;
  background: #f5f5f5;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.Chat__input1:focus-within, .Chat__input1md:focus-within, .Chat__input1md__block:focus-within, .Chat__input1__block:focus-within {
  border: 1px solid var(--color-prim-800);
}
.Chat__input1__block {
  background: #d8d8d8;
}
.Chat__input1md, .Chat__input1md__block {
  width: calc(100vw - 76px);
}
.Chat__input1md__block {
  background: #d8d8d8;
}
.Chat__inputfield1 {
  width: 100%;
  max-width: 870px;
  font-size: 16px;
  font-weight: 400;
  color: #222;
  padding: 15px;
  border: none;
  background: none;
  line-height: 1;
}
.Chat__inputfield1:focus-visible {
  outline: none;
  border: none;
  box-shadow: none;
}
.Chat__inputButtonWrap {
  width: 70px;
  display: flex;
  justify-content: center;
}
.Chat__inputButton {
  width: 18px;
  border: none;
  color: #888;
  font-size: 18px;
  background-color: transparent;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0;
}
.Chat__flex, .Chat__flex__col {
  width: 100%;
  display: flex;
  justify-content: center;
}
.Chat__flex__col {
  flex-direction: column;
}
.Chat__chatTopWrap, .Chat__chatTopWrap__mdDemo, .Chat__chatTopWrap__md {
  width: 100%;
  height: calc(100vh - 86px);
}
.Chat__chatTopWrap__md {
  height: 100%;
}
.Chat__chatTopWrap__mdDemo {
  height: calc(100% - 77px);
}
.Chat__container {
  width: 100%;
  max-width: 900px;
  height: 100%;
  padding: 0 24px;
}
@media (max-width: 600px) {
  .Chat__container {
    padding: 0 16px;
  }
}
.Chat__box, .Chat__box__p16 {
  width: 100%;
  min-height: 70px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border: 1px solid #CCC;
  border-radius: 10px;
  margin: 16px 0;
}
.Chat__box__p16 {
  padding: 16px;
}
.Chat__side {
  width: 240px;
  height: 100%;
}
@media (max-width: 1080px) {
  .Chat__side {
    width: 0;
  }
}
.Chat__main {
  width: 100%;
  height: calc(100vh - 86px - 77px);
  overflow-y: scroll;
  overflow-x: hidden;
}
@media screen and (max-width: 600px) {
  .Chat__main {
    height: calc(100vh - 86px);
  }
}
.Chat__mainmd, .Chat__mainmd__onboard {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}
.Chat__mainmd__onboard {
  padding: 0 16px;
}
.Chat__greetingWrap, .Chat__greetingWrap__md {
  width: fit-content;
  height: auto;
  display: flex;
  align-items: flex-start;
}
.Chat__greetingWrap__md {
  margin: 0 16px;
}
.Chat__greetingContent {
  width: 100%;
  background: #efefef;
  border-radius: 10px;
  padding: 12px 14px;
}
.Chat__greetingmd {
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  margin: 16px 0;
}
.Chat__inlineBtn {
  width: 100%;
  display: flex;
  overflow-x: scroll;
  margin-top: 16px;
}
.Chat__inlineBtn ::-webkit-scrollbar {
  display: none;
}
.Chat__back {
  width: 100%;
  height: 145px;
  flex-shrink: 0;
}
.Chat__backmd {
  width: 100%;
  height: 86px;
  flex-shrink: 0;
}
.Chat__bottom, .Chat__bottommd {
  width: 100%;
  min-height: 86px;
  position: absolute;
  bottom: 0;
  background: #ffffff;
  left: 0;
  z-index: 3;
}
@media screen and (max-width: 960px) {
  .Chat__bottom, .Chat__bottommd {
    padding: 0 16px;
  }
}
.Chat__bottommd {
  left: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.Chat__toggleGroup {
  width: 100%;
  text-align: center;
  padding-top: 24px;
  display: flex;
  justify-content: center;
}
@media (max-width: 1080px) {
  .Chat__toggleGroup {
    padding-top: 60px;
  }
}
.Chat__toggleGroupDemo {
  width: 100%;
  min-height: 45px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Chat__toggleBack {
  width: 228px;
  height: 45px;
  background: #efefef;
  border-radius: 100px;
  padding: 8px;
}
.Chat__toggle {
  width: 106px;
  height: 29px;
  background: #efefef;
  border: none;
  border-radius: 100px;
  cursor: pointer;
}
.Chat__bottombtn {
  width: fit-content;
  min-width: fit-content;
  height: fit-content;
  border: none;
  border-radius: 10px;
  background: #fff;
  color: #222;
  padding: 6px 12px;
  margin-left: 44px;
  margin-bottom: 16px;
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
}
@media screen and (min-width: 600px) {
  .Chat__bottombtn {
    font-size: 15px;
  }
}
.Chat__footer, .Chat__footer__md {
  width: 100%;
  min-height: 22px;
  background: #fff;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.Chat__footer a, .Chat__footer__md a {
  font-weight: 500;
  font-size: 12px;
  line-height: 1;
  color: #999;
}
.Chat__footer__md {
  min-height: 22px;
}
.Chat__infoModal {
  position: absolute;
  top: 0px;
  left: -210px;
  width: 200px;
  height: 150px;
  background: #d8d8d8;
  border-radius: 10px;
  padding: 8px 16px;
  color: #777;
  font-size: 14px;
  text-align: center;
}
.Chat__infoModal::after {
  content: "";
  position: absolute;
  top: 16px;
  right: -22px;
  width: 0;
  height: 0;
  border-bottom: 6px solid transparent;
  border-top: 6px solid transparent;
  border-left: 12px solid #d8d8d8;
  border-right: 12px solid transparent;
}
@media screen and (max-width: 1400px) {
  .Chat__infoModal::after {
    left: -22px;
    border-bottom: 6px solid transparent;
    border-top: 6px solid transparent;
    border-left: 12px solid transparent;
    border-right: 12px solid #d8d8d8;
  }
}
@media screen and (max-width: 1400px) {
  .Chat__infoModal {
    left: 40px;
  }
}
.Chat__modalTail {
  position: absolute;
  right: -16px;
  width: 0;
  height: 0;
  border-bottom: 8px solid transparent;
  border-top: 8px solid #eee;
  border-left: 8px solid #eee;
  border-right: 8px solid transparent;
}
.Chat__skeletonWrap {
  width: 100%;
  height: 16px;
  display: flex;
  background-color: #e1e1e1;
  border-radius: 99px;
  overflow: hidden;
  position: relative;
}
.Chat__skeleton {
  position: absolute;
  width: 50%;
  height: 100%;
  min-width: 400px;
  min-height: 400px;
  background: linear-gradient(90deg, rgba(192, 192, 192, 0) 0%, rgb(192, 192, 192) 48%, rgba(192, 192, 192, 0) 100%);
  animation: moveRight 2.1s linear infinite;
}
.Chat__progressWrap, .Chat__progressWrap__md {
  width: 100%;
  max-width: 888px;
  height: 16px;
  background-color: #efefef;
  border-radius: 10px;
  overflow: hidden;
}
.Chat__progressWrap__md {
  width: calc(100vw - 72px - 32px);
}
.Chat__progress, .Chat__progress__md {
  width: 600px;
  height: 400px;
  background: linear-gradient(to right, rgba(225, 225, 225, 0) 0%, rgb(225, 225, 225) 30%, rgba(225, 225, 225, 0) 100%);
  border-radius: 10px;
  animation: moveRight 2s ease-in-out infinite;
}
@media screen and (max-width: 960px) {
  .Chat__progress, .Chat__progress__md {
    width: 400px;
  }
}
@media screen and (max-width: 600px) {
  .Chat__progress, .Chat__progress__md {
    width: 200px;
  }
}
.Chat__progress__md {
  width: 200px;
}
.Chat__progressTextWrap {
  display: flex;
  justify-content: center;
}
.Chat__progressText {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 16px;
}
.Chat__progressText span {
  font-size: 8px;
  color: #bbb;
  position: relative;
  animation: textUp 1.2s linear infinite;
}
.Chat__progressText span:nth-of-type(1) {
  animation-delay: 0s;
}
.Chat__progressText span:nth-of-type(2) {
  animation-delay: 0.2s;
}
.Chat__progressText span:nth-of-type(3) {
  animation-delay: 0.4s;
}
.Chat__progressText span:nth-of-type(4) {
  animation-delay: 0.6s;
}
.Chat__progressText span:nth-of-type(5) {
  animation-delay: 0.8s;
}

.Sidebar {
  width: 240px;
  height: 100%;
  position: relative;
  top: 0;
  left: 0;
  background-color: #202023;
  z-index: 9999;
}
.Sidebar__productList {
  position: relative;
  top: 12px;
  height: calc(100vh - 86px);
  background-color: transparent !important;
}
.Sidebar__productList__product {
  position: relative;
  left: 8px;
  right: 8px;
  width: calc(100% - 16px);
  max-width: 218px;
  min-height: 40px !important;
  padding: 8px !important;
  border-radius: 6px !important;
  background-color: transparent !important;
  color: #DDDDDD !important;
  box-shadow: none !important;
  justify-content: flex-start !important;
  align-items: center !important;
  text-align: left;
  font-size: 14px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis !important;
}
.Sidebar__productList__product::after {
  content: "";
  position: absolute;
  width: 48px;
  height: 100%;
  right: 0;
  top: 0;
  background: linear-gradient(to right, transparent, #202023 90%);
}
.Sidebar__productList__product:hover {
  background-color: #3B3B3B !important;
}
.Sidebar__button {
  left: 8px;
  right: 8px;
  width: calc(100% - 16px);
  height: 36px;
  padding: 8px !important;
  border-radius: 6px !important;
  background-color: transparent !important;
  color: #FFFFFF !important;
  box-shadow: none !important;
  justify-content: flex-start !important;
  align-items: center !important;
  text-align: left;
  font-size: 14px !important;
}
.Sidebar__button:hover {
  background-color: #3B3B3B !important;
}

.Topbar {
  width: 100%;
  height: 48px;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #34343f;
  z-index: 9999;
}
.Topbar__button {
  top: 8px;
  right: 8px;
  height: 32px;
  border-radius: 4px !important;
  background-color: transparent !important;
  color: #CCCCCC !important;
  box-shadow: none !important;
  font-size: 14px !important;
}
.Topbar__button:hover {
  color: #FFFFFF !important;
}

.Modal__hyperClova {
  position: absolute;
  bottom: 24px !important;
  left: 24px !important;
  width: 160px;
  height: auto;
  background-color: #F5F5F5 !important;
  border: none !important;
}
.Modal__button {
  position: absolute;
  bottom: 24px !important;
  right: 24px !important;
  padding: 8px;
  font-size: 12px !important;
  background-color: white !important;
  border: none !important;
}

.Util__bold {
  font-weight: 600;
}
.Util__textStart {
  text-align: start;
}
.Util__w100mw {
  width: 100%;
  max-width: 960px;
}

.chatContainer {
  display: flex;
  flex-direction: column;
  height: calc(var(--vh, 1vh) * 100);
  justify-content: flex-end;
}

.messageList {
  flex: 1;
  overflow-y: auto;
  padding-bottom: 80px;
}

.inputContainer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  padding: 10px;
  box-shadow: 0 -1px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

/* css */
.Test__wrapper {
  position: relative;
  width: 100%;
  height: 200px;
  overflow-y: auto;
}
.Test__content {
  width: 100%;
  height: auto;
}
.Test__makeScrollable {
  position: absolute;
  left: 0;
  width: 1px;
  height: calc(100% + 1px);
}

/*# sourceMappingURL=Chat.module.css.map */
