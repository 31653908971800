.Mockup {
    &__buttonWrap {
        position: absolute; 
        bottom: 0px; 
        width: 100%; 
        display: flex; 
        align-items: center; 
        justify-content: center;
        background: #fff;
        padding: 16px 0;
    }

    &__button {
        width: 39%; 
        height: 48px; 
        background: #FE5000; 
        border-radius: 8px; 
        color: #fff; 
        display: flex; 
        align-items: center; 
        justify-content: center; 
        cursor: pointer;

        @media screen and (max-width: 600px) {
            width: 92%;
            height: 66px;
            border-radius: 10px;
        }
    }
}

.Guide {
    &__img {
        position: fixed; 
        bottom: 67px; 
        right: 7px; 
        z-index: 5;

        @media screen and (max-width: 600px) {
            bottom: 82px;
        }
    }
}