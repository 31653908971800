@import './global.scss';
@import './guideVar.scss';

:root {
    --carousel-card-width: 100%;
    --carousel-wrap-width: fit-content;
    --carousel-wrap-width-shrink: fit-content;
    --carousel-wrap-height: 150px;
    --carousel-wrap-height-shrink: 50px;
    --carousel-wrap-transform: translateX(0);
}

.CarouselContainer {
    &__container {
        width: 100%;
        height: fit-content;
        overflow: hidden;
        position: relative;
    }

    &__scrollBox {
        width: var(--carousel-card-width);
        height: var(--carousel-wrap-height);
        overflow-x: scroll;
        // -webkit-overflow-scrolling: touch;
        // scroll-snap-type: x mandatory;
        transition: transform 0.3s ease-out;

        &__shrink {
            @extend .CarouselContainer__scrollBox;
            
            height: var(--carousel-wrap-height-shrink);
        }
    }

    &__wrap {
        width: var(--carousel-wrap-width);
        height: 100%;
        display: flex;
        overflow: hidden;
        transform: var(--carousel-wrap-transform);

        &__shrink {
            @extend .CarouselContainer__wrap;
            width: var(--carousel-wrap-width-shrink);
            height: var(--carousel-wrap-height-shrink);
        }

        ::-webkit-scrollbar {
            display: none;
        }
    }
}

.carousel {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 100%;
  transition: transform 0.3s ease-out;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  
  &:active {
    cursor: grabbing;
  }
}

.ProductCard {
    width: 100%;
    height: 100%;
    padding: 8px 16px 4px 16px;
    display: flex;
    transition: transform 0.3s ease-out;
    // scroll-snap-align: start;

    &__shrink {
        @extend .ProductCard;
        height: 42px;
        padding: 8px 56px 16px 56px;
    }
  
    &__productImage {
        width: 132px;
        min-width: 132px;
        height: 132px;
        min-height: 132px;
        object-fit: cover;
        border-radius: 8px;
        border: 1px solid $color-gray-200;

        &__shrink {
            @extend .ProductCard__productImage;
            width: 34px;
            height: 34px;
        }
    }

    &__productInfo {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;

        &__shrink {
            @extend .ProductCard__productInfo;
            flex-direction: row;
        }
    }

    &__companyName {
        @extend .h8;
        @extend .fc-gray-500;
        min-height: 16px;
        height: 16px;
    }

    &__productName {
        @extend .h6;
        @extend .fc-gray-800;
        width: 100%;
        height: 32px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;

        &__shrink {
            @extend .ProductCard__productName;
            @extend .h9;
        }
    }

    &__priceWrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
    }
    
    &__discountRate {
        @extend .h4;
        @extend .fc-warning;
    }

    &__productPrice {
        @extend .h4;
        @extend .fc-gray-800;
    }

    &__reviewWrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
    }

    &__reviewScore {
        @extend .h9;
        @extend .fc-gray-500;
    }

    &__detailButton {
        width: 100%;
        height: 34px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid $color-gray-300;
        border-radius: 10px;
        padding: 0 10px;
        cursor: pointer;
    }

    &__detailButtonText {
        @extend .h8;
        @extend .fc-gray-600;
        cursor: pointer;
    }

    &__paginationContainer {
        width: 100%;
        height: 22px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 6px 0 12px 0;
    }
}

.ProductShrinkView {
    &__carouselCardWrap {
        display: flex;
        width: 100%;
        max-height: 50px;
        justify-content: flex-start;
        padding: 8px 56px;
        padding-bottom: 0;
        gap: 8px;
    }

    &__productCardWrap {
        width: 100%;
        height: 100%;
        padding: 8px 0;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        text-align: left;
    }

    &__image {
        width: 34px;
        min-width: 34px;
        height: 34px;
        min-height: 34px;
        object-fit: cover;
        border-radius: 8px;
        border: 1px solid $color-gray-200;
    }
}

.ExtraPadding {
    &__horizontal {
        padding-left: 56px;
        padding-right: 56px;
    }
}