.Mockup__buttonWrap {
  position: absolute;
  bottom: 0px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  padding: 16px 0;
}
.Mockup__button {
  width: 39%;
  height: 48px;
  background: #FE5000;
  border-radius: 8px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
@media screen and (max-width: 600px) {
  .Mockup__button {
    width: 92%;
    height: 66px;
    border-radius: 10px;
  }
}

.Guide__img {
  position: fixed;
  bottom: 67px;
  right: 7px;
  z-index: 5;
}
@media screen and (max-width: 600px) {
  .Guide__img {
    bottom: 82px;
  }
}/*# sourceMappingURL=Mockup.module.css.map */