@import url(https://cdn.jsdelivr.net/gh/moonspam/NanumSquare@2.0/nanumsquare.css);
@import './guideVar.scss';

@font-face {
  font-family: 'pretendard';
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/Pretendard-Light.woff") format('woff');
  /* light */
}

@font-face {
  font-family: 'pretendard';
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/Pretendard-Regular.woff") format('woff');
  /* regular */
}

@font-face {
  font-family: 'pretendard';
  font-style: normal;
  font-weight: 600;
  src: url("../fonts/Pretendard-SemiBold.woff") format('woff');
  /* semi bold */
}

@font-face {
  font-family: 'pretendard';
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/Pretendard-Bold.woff") format('woff');
  /* regular */
}

html {
  -webkit-text-size-adjust: none;
  // font-size: 62.5%;
}

#root {
  width: 100%;
  max-width: 100%;
  --vh: 100%;
  height: 100%;
  overflow-x: hidden;
  -webkit-text-size-adjust: none;
}

body,
html {
  width: 100%;
  max-width: 100%;
  // height: 100vh;
  height: var(--vh);
  // min-height: 100vh;
  overflow-y: hidden;
  font-family: 'pretendard';
  margin: 0;
  overflow-x: hidden;
  color: $color-gray-800;

  * {
    box-sizing: border-box;
  }

  // * {
  //   font-family: 'pretendard';
  //   font-size: 15px;

  //   @media screen and (min-width: 600px) {
  //         font-size: 16px;
  //   }
  // }

  -webkit-text-size-adjust: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &:focus-visible {
    outline: none;
    border: 1px solid var(color-prim-800);
    border-radius: 10px;
    transform: translateZ(0);
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

// typography
h1,
h2,
h3,
h4,
h5,
h6,
p,
.label,
span,
input,
input::placeholder {
  margin: 0;
  font-family: 'pretendard', sans-serif;

  &:focus-visible {
    outline: none;
    box-shadow: none;
  }
}

.h1 {
  font-family: 'pretendard', sans-serif;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.4px;
  font-weight: $font-weight-bold;
}

.h2 {
  font-family: 'pretendard', sans-serif;
  font-size: 15px;
  line-height: 19px;
  letter-spacing: -0.375px;
  font-weight: $font-weight-bold;
}

.h3 {
  font-family: 'pretendard', sans-serif;
  font-size: 15px;
  line-height: 19px;
  letter-spacing: -0.375px;
  font-weight: $font-weight-regular;
}

.h4 {
  font-family: 'pretendard', sans-serif;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.35px;
  font-weight: $font-weight-bold;
}

.h5 {
  font-family: 'pretendard', sans-serif;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.35px;
  font-weight: $font-weight-semibold;
}

.h6 {
  font-family: 'pretendard', sans-serif;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.35px;
  font-weight: $font-weight-regular;
}

.h7 {
  font-family: 'pretendard', sans-serif;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: -0.325px;
  font-weight: $font-weight-bold;
}

.h8 {
  font-family: 'pretendard', sans-serif;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: -0.3px;
  font-weight: $font-weight-semibold;
}

.h9 {
  font-family: 'pretendard', sans-serif;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: -0.3px;
  font-weight: $font-weight-regular;
}

.h10 {
  font-family: 'pretendard', sans-serif;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: -0.275px;
  font-weight: $font-weight-semibold;
}

.h11 {
  font-family: 'pretendard', sans-serif;
  font-size: 10px;
  line-height: 13px;
  letter-spacing: -0.25px;
  font-weight: $font-weight-semibold;
}

.badge-font {
  font-family: 'pretendard', sans-serif;
  font-size: 10px;
  line-height: 10px;
  font-weight: $font-weight-semibold;
}

.vivino-font {
  font-family: 'pretendard', sans-serif;
  font-size: 10px;
  line-height: 1.2;
  font-weight: $font-weight-bold;
  color: #aa1329;
}

.sub-title {
  font-family: 'pretendard', sans-serif;
  font-size: 18px;
  line-height: 1.2;
  font-weight: $font-weight-regular;
}

.line-clamp-1 {
  width: 100%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  overflow: hidden;
}

.line-clamp-2 {
  width: 100%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  overflow: hidden;
}

button,
a {
  cursor: pointer;
  -webkit-text-fill-color: inherit;
  color: inherit;
}

a {
  text-decoration: none;

  &:-webkit-any-link {
    color: inherit;
  }
}

ul,
ol {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.style.Chat__inputfield1:focus-visible {
  outline: 0px;
}

:focus-visible {
  outline: none;
  box-shadow: 0 0 0 2px inset var(--color-prim-800);
  // outline: 2px solid var(--color-prim-800);
}

li::marker {
  text-indent: 0px;
  text-align: start;
  text-align-last: start;
}

/* width와 height는 스크롤바의 크기를 지정합니다. */
/* thumb-radius는 스크롤바 위아래 버튼의 크기를 지정합니다. */
/* track-color는 스크롤바 바탕 색상을 지정합니다. */
/* thumb-color는 스크롤바 버튼 색상을 지정합니다. */
/* thumb-active-color는 스크롤바 버튼을 클릭할 때 나타나는 색상을 지정합니다. */
::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0);
}

::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0);
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0);
}

::-webkit-scrollbar-thumb:active {
  background-color: rgba(0, 0, 0, 0);
}

.fc-prim-800 {
  color: #154cca;
}

.fc-prim-900 {
  color: #103b9c;
}

.fc-prim-700 {
  color: #0025E4;
}

.fc-prim-300 {
  color: #AAB8ED;
}

.fc-prim-200 {
  color: #bbc8e5;
}

.fc-prim-150 {
  color: #E0E5F8;
}

.fc-prim-100 {
  color: #e0e6f3;
}

.fc-bg-100 {
  color: #f7f7f7;
}

.fc-gray-900 {
  color: #000000;
}

.fc-gray-800 {
  color: #222222;
}

.fc-gray-700 {
  color: #444444;
}

.fc-gray-600 {
  color: #666666;
}

.fc-gray-500 {
  color: #999999;
}

.fc-gray-400 {
  color: #c0c0c0;
}

.fc-gray-300 {
  color: #e1e1e1;
}

.fc-gray-200 {
  color: #efefef;
}

.fc-gray-100 {
  color: #f6f6f6;
}

.fc-white {
  color: #ffffff;
}

.fc-positive-900 {
  color: #0cbd48;
}

.fc-positive-300 {
  color: #d6eddc;
}

.fc-warning {
  color: #ff5555;
}

.fc-review {
  color: #ffc738;
}

.fc-emphasis {
  color: #ec3e48;
}

.fc-text {
  color: #222222;
}

.spacing-2 {
  width: $spacing2;
  min-width: $spacing2;
  height: $spacing2;
  min-height: $spacing2;
}

.spacing-4 {
  width: $spacing4;
  min-width: $spacing4;
  height: $spacing4;
  min-height: $spacing4;
}

.spacing-6 {
  width: $spacing6;
  min-width: $spacing6;
  height: $spacing6;
  min-height: $spacing6;
}

.spacing-8 {
  width: $spacing8;
  min-width: $spacing8;
  height: $spacing8;
  min-height: $spacing8;
}

.spacing-12 {
  width: $spacing12;
  min-width: $spacing12;
  height: $spacing12;
  min-height: $spacing12;
}

.spacing-16 {
  width: $spacing16;
  min-width: $spacing16;
  height: $spacing16;
  min-height: $spacing16;
}

.spacing-20 {
  width: $spacing20;
  min-width: $spacing20;
  height: $spacing20;
  min-height: $spacing20;
}

.spacing-24 {
  width: $spacing24;
  min-width: $spacing24;
  height: $spacing24;
  min-height: $spacing24;
}

.spacing-32 {
  width: $spacing32;
  min-width: $spacing32;
  height: $spacing32;
  min-height: $spacing32;
}

.spacing-40 {
  width: $spacing40;
  min-width: $spacing40;
  height: $spacing40;
  min-height: $spacing40;
}

.spacing-48 {
  width: $spacing48;
  min-width: $spacing48;
  height: $spacing48;
  min-height: $spacing48;
}

.spacing-56 {
  width: $spacing56;
  min-width: $spacing56;
  height: $spacing56;
  min-height: $spacing56;
}

.spacing-72 {
  width: $spacing72;
  min-width: $spacing72;
  height: $spacing72;
  min-height: $spacing72;
}

.spacing-80 {
  width: $spacing80;
  min-width: $spacing80;
  height: $spacing80;
  min-height: $spacing80;
}

.hide {
  display: none;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.pointer {
  cursor: pointer;
}

.non-clickable {
  cursor: not-allowed !important;
}