@import './global.scss';
@import './guideVar.scss';

.ChatInput {
    width: 100%;
    height: 106px;
    min-height: 106px;
    background-color: #fff;
    position: relative;

    &__suggestions {
        width: 100%;
        height: 45px;
        position: relative;
        overflow-x: scroll;
        overflow-y: hidden;
        padding: 0 16px;

        &__scrollContent {
            width: fit-content;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 8px;
            white-space: nowrap;
        }

        &__mobile {
            @extend .ChatInput__suggestions;
            -ms-overflow-style: none;
            scrollbar-width: none;
            
            &::-webkit-scrollbar {
                display: none;
            }
        }

        &__desktop {
            @extend .ChatInput__suggestions;

            &::-webkit-scrollbar {
                height: 15px;
                display: block;
            }
            &::-webkit-scrollbar-track {
                background: $color-white;
                padding: 0 8px;
            }
            &::-webkit-scrollbar-thumb {
                background-color: $color-gray-400;
                border: 3px solid $color-white;
                border-radius: 99px;
            }
        }

        &__item {
            width: fit-content;
            height: fit-content;
            background-color: white;
            border-radius: 999px;
            background: var(--color-prim-100);
            padding: 6px 14px;
            cursor: pointer;

            p {
                color: var(--color-prim-800);
            }

            &:hover {
                background: var(--color-prim-200);
            }

            &:active {
                background: var(--color-prim-800);
                p {
                    color: $color-white;
                }
            }
        }
    }

    &__inputContainer {
        width: 100%;
        height: 64px;

        &__wrapper {
            width: 100%;
            height: 60px;
            display: flex;
            padding: 8px 16px;
            align-items: center;
        }
    }

    &__inputWrapper {
        flex: 1;
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: $color-gray-100;
        border-radius: 10px;
        padding: 8px 16px;

        &:focus-within {
            outline: 2px solid var(--color-prim-800);
        }

        &__disabled {
            @extend .ChatInput__inputWrapper;
            background-color: $color-gray-300;
        }

        input {
            width: 100%;
            height: 100%;
            background-color: transparent;
            border: none;
            outline: none;
            @extend .h3;
            color: $color-gray-800;

            &::placeholder {
                color: $color-gray-500;
            }

            &:focus-visible {
                outline: none;
                box-shadow: none;
            }
        }

    }

    &__resetIcon {
        width: 32px;
        height: 32px;
        cursor: pointer;
    }

    &__sendIcon {
        width: 28px;
        height: 28px;
        cursor: pointer;
    }

    &__poweredBy {
        width: 100%;
        height: 22px;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        
        p {
            color: $color-gray-500;
        }
    }
}
