@import './global.scss';
@import './guideVar.scss';

.CollectionForm {
    &__container {
        width: 100%;
        padding: 8px 16px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }

    &__wrapper {
        width: fit-content;
        height: fit-content;
        border: 1px solid $color-gray-300;
        border-radius: 10px;
        padding: 16px;
    }

    &__group {
        margin-bottom: 20px;

        input:disabled {
            color: $color-gray-600;
            border: 1px solid $color-gray-300;
        }
    }

    &__label {
        display: block;
        margin-bottom: 5px;
        font-size: 14px;

    }
    &__required {
        &::after {
            content: " *";
            color: $color-warning;
        }
    }

    &__input {
        width: 100%;
        padding: 12px;
        border: 2px solid var(--color-prim-800);
        border-radius: 8px;
        background-color: $color-gray-100;
        font-size: 14px;

        &:focus-visible {
            outline: none;
            box-shadow: none;
        }

        &__warning {
            @extend .CollectionForm__input;
            border: 2px solid $color-warning;
        }
    }

    &__warning {
        display: flex;
        align-items: center;
        gap: 4px;
    }
    
    &__checkboxGroup {
        margin-bottom: 20px;
    }

    &__checkboxLabel {
        display: flex;
        align-items: flex-start;
        font-size: 14px;
        color:$color-gray-700;
        margin-bottom: 12px;
        cursor: pointer;

        input[type="checkbox"] {
            margin-right: 8px;
            margin-top: 2px;
        }

        span {
            line-height: 1.4;
        }
    }

    &__button {
        width: 100%;
        padding: 15px;
        background-color: var(--color-prim-800);
        border: none;
        border-radius: 8px;
        color: $color-white;
        font-size: 14px;
        cursor: pointer;
        transition: background-color 0.2s ease;

        &__disabled {
            @extend .CollectionForm__button;
            background-color: $color-white;
            border: 1px solid $color-gray-300;
            color: $color-gray-500;
            cursor: not-allowed;
        }
    }

    &__link {
        color: $color-gray-700;
        text-decoration: underline;
        font-weight: 600;
    }
}