:root {
  --color-prim-800: #154cca;
  --color-prim-200: #bbc8e5;
  --color-prim-100: #e0e6f3;
}

.Spacing__2 {
  width: 2px;
  min-width: 2px;
  height: 2px;
  min-height: 2px;
}
.Spacing__4 {
  width: 4px;
  min-width: 4px;
  height: 4px;
  min-height: 4px;
}
.Spacing__8 {
  width: 8px;
  min-width: 8px;
  height: 8px;
  min-height: 8px;
}
.Spacing__12 {
  width: 12px;
  min-width: 12px;
  height: 12px;
  min-height: 12px;
}
.Spacing__16 {
  width: 16px;
  min-width: 16px;
  height: 16px;
  min-height: 16px;
}
.Spacing__24 {
  width: 24px;
  min-width: 24px;
  height: 24px;
  min-height: 24px;
}
.Spacing__32 {
  width: 32px;
  min-width: 32px;
  height: 32px;
  min-height: 32px;
}
.Spacing__40 {
  width: 40px;
  min-width: 40px;
  height: 40px;
  min-height: 40px;
}
.Spacing__48 {
  width: 48px;
  min-width: 48px;
  height: 48px;
  min-height: 48px;
}
.Spacing__56 {
  width: 56px;
  min-width: 56px;
  height: 56px;
  min-height: 56px;
}
.Spacing__72 {
  width: 72px;
  min-width: 72px;
  height: 72px;
  min-height: 72px;
}
.Spacing__80 {
  width: 80px;
  min-width: 80px;
  height: 80px;
  min-height: 80px;
}

.Button__primary, .Banner__floating__button, .Banner__floating__minimize__small__button {
  font-size: 15px;
  font-family: "pretendardBold";
  color: #ffffff;
  width: 100%;
  max-width: 189px;
  height: 34px;
  background: var(--color-prim-800);
  border: none;
  border-radius: 10px;
}
.Button__primary:active, .Banner__floating__button:active, .Banner__floating__minimize__small__button:active {
  background: #103b9c;
}
.Button__primary:disabled, .Banner__floating__button:disabled, .Banner__floating__minimize__small__button:disabled {
  background: #f6f6f6;
}
.Button__examStatement, .Button__examStatement__md {
  width: -moz-fit-content;
  width: fit-content;
  background: var(--color-prim-100);
  color: var(--color-prim-800);
  border: none;
  height: -moz-fit-content;
  height: fit-content;
  min-height: 25px;
  border-radius: 10px;
  padding: 12px 14px;
}
.Button__examStatement:active, .Button__examStatement__md:active {
  background: var(--color-prim-200);
}
.Button__examStatement *, .Button__examStatement__md * {
  text-align: left;
}
.Button__examStatement__md {
  margin: 0 16px;
}
.Button__reset {
  width: 83px;
  min-width: 83px;
  height: 44px;
  border: 1px solid #999999;
  border-radius: 100px;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Button__reset:hover {
  background: #f6f6f6;
}
.Button__reset:active {
  background: #e1e1e1;
}
.Button__reset__md {
  background: transparent;
  width: 36px;
  height: 36px;
  border: 1px solid #999999;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Button__carouselFAB {
  width: 161px;
  height: 38px;
  position: relative;
  top: 0;
  left: 0;
  border: 1px solid #e1e1e1;
  border-radius: 100px;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.15);
  z-index: 1;
}
.Button__carouselSC {
  width: 100%;
  height: 91px;
  position: relative;
  top: 0;
  left: 0;
  padding: 16px;
  border: none;
  border-bottom: 1px solid #e1e1e1;
  background: rgba(255, 255, 255, 0.95);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}
.Button__subs {
  width: 145px;
  height: 39px;
  background: var(--color-prim-800);
  border: none;
  border-radius: 10px;
  margin-top: 20px;
  margin-right: 14px;
}
.Button__subs p {
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  color: #ffffff;
}
@media screen and (max-width: 960px) {
  .Button__subs {
    width: 100%;
    margin: 0;
  }
}

.Label__badge, .Label__badge__related, .Label__badge__recommendedFill, .Label__badge__recommended {
  width: -moz-fit-content;
  width: fit-content;
  height: 21px;
  border-radius: 6px;
  background: #f6f6f6;
  padding: 0 8px;
}
.Label__badge__recommended {
  color: var(--color-prim-800);
}
.Label__badge__recommendedFill {
  color: #ffffff;
  background: var(--color-prim-800);
}
.Label__badge__related {
  color: #999999;
}

.Banner__subsWrap {
  width: 100%;
  padding-left: 40px;
  padding-bottom: 16px;
}
@media screen and (max-width: 960px) {
  .Banner__subsWrap__img2 {
    display: none;
  }
}
.Banner__subsWrap__md {
  padding-left: 56px;
  padding-right: 16px;
  margin: 0;
}
.Banner__subs, .Banner__subs__md {
  width: 100%;
  background: #ffffff;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;
}
.Banner__subs__md {
  padding: 16px;
}
.Banner__subs__content {
  font-size: 18px;
  font-weight: 700;
  line-height: 32px;
}
.Banner__subs__content__md {
  font-size: 15px;
  font-weight: 600;
  line-height: 21px;
}
@media screen and (max-width: 960px) {
  .Banner__subs, .Banner__subs__md {
    flex-direction: column;
  }
}
.Banner__floating__container {
  position: absolute;
  bottom: 102px;
  right: 16px;
  border-radius: 16px;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.15);
  overflow: hidden;
  z-index: 3;
}
.Banner__floating__wrap {
  position: relative;
  width: 350px;
  height: -moz-fit-content;
  height: fit-content;
  background: #ffffff;
  border-radius: 16px;
  z-index: 2;
}
.Banner__floating__title {
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
}
.Banner__floating__content {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-top: 8px;
  color: #666666;
}
.Banner__floating__button, .Banner__floating__minimize__small__button {
  height: 38px;
  margin-left: 16px;
  font-weight: 700;
}
.Banner__floating__minimize__small__button {
  height: -moz-fit-content;
  height: fit-content;
  padding: 16px;
  border-radius: 16px;
  margin: 0;
}
.Banner__floating__minimize__small__img {
  vertical-align: middle;
  margin-right: 4px;
}

.Indicator__line__container {
  display: flex;
  gap: 0px;
  justify-content: center;
  align-items: center;
  width: 20%;
  border-radius: 999px;
  overflow: hidden;
}
.Indicator__line__dot {
  width: 6px;
  height: 4px;
  border-radius: 999px;
  background-color: #e1e1e1;
  transition: background-color 0.1s ease-in-out;
}/*# sourceMappingURL=Components.module.css.map */