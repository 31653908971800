@import './global.scss';
@import './guideVar.scss';

@keyframes typingAnimation {
    0%, 20%, 70%, 100% {
        transform: translateY(0);
        opacity: 0.4;
    }
    10% {
        transform: translateY(-6px);
        opacity: 1;
    }
}

.typingIndicator {
    display: flex;
    gap: 5px;
    padding: 6px 2px;

    span {
        width: 8px;
        height: 8px;
        background-color: rgb(92, 102, 111);
        border-radius: 50%;
        animation: typingAnimation 1.6s infinite;
        display: inline-block;

        &:nth-child(2) {
            animation-delay: 0.2s;
        }

        &:nth-child(3) {
            animation-delay: 0.4s;
        }
    }
}

.Bubble {
    &__wrapper {
        width: 100%;
        height: fit-content;
        display: flex;
        padding: 8px 16px;
    }

    &__myWrapper {
        @extend .Bubble__wrapper;
        justify-content: flex-end;
    }

    &__name {
        @extend .h9;
        color: $color-gray-500;
    }

    &__chatbotMessage {
        @extend .h3;
        color: $color-gray-800;
    }

    &__myMessage {
        @extend .h3;
        color: $color-white;
    }

    &__chatbotLoadingMessageWrapper {
        width: 100%;
        height: fit-content;
        background-color: $color-gray-200;
        border-radius: 10px;
        padding: 12px 14px;
    }

    &__chatbotMessageWrapper {
        width: fit-content;
        height: fit-content;
        background-color: $color-gray-200;
        border-radius: 10px;
        padding: 12px 14px;
    }

    &__myMessageWrapper {
        width: fit-content;
        height: fit-content;
        background-color: var(--color-prim-800);
        border-radius: 10px;
        padding: 12px 14px;
    }
}