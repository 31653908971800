@import './global.scss';
@import './guideVar.scss';

.PreQuestion {
    &__input {
        width: 100%;
        padding: 12px;
        border: 2px solid var(--color-prim-800);
        border-radius: 8px;
        background-color: $color-gray-100;
        font-size: 14px;

        &:focus-visible {
            outline: none;
            box-shadow: none;
        }

        &__warning {
            @extend .PreQuestion__input;
            border: 2px solid $color-warning;
        }
    }

    &__warning {
        display: flex;
        align-items: center;
        gap: 4px;
    }

    &__submitButton {
        width: calc(100% - 32px);
        height: fit-content;
        position: fixed;
        bottom: 16px;
        left: 16px;
        background-color: #000;
        border: 1px solid #e1e1e1; 
        border-radius: 10px; 
        padding: 16px;

        &__disabled {
            @extend .PreQuestion__submitButton;
            background-color: #e1e1e1;
            cursor: not-allowed;
        }
    }
}

.MultiSelect {
    &__option {
        padding: 14px 16px;
        border: 1px solid #e1e1e1;
        border-radius: 999px;
        cursor: pointer;

        &__checked {
            @extend .MultiSelect__option;
            border: 1px solid var(--color-prim-800);
        }
    }
}