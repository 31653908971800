@import './guideVar.scss';

.Spacing {
    &__2 {
        width: $spacing2;
        min-width: $spacing2;
        height: $spacing2;
        min-height: $spacing2;
    }

    &__4 {
        width: $spacing4;
        min-width: $spacing4;
        height: $spacing4;
        min-height: $spacing4;
    }

    &__8 {
        width: $spacing8;
        min-width: $spacing8;
        height: $spacing8;
        min-height: $spacing8;
    }

    &__12 {
        width: $spacing12;
        min-width: $spacing12;
        height: $spacing12;
        min-height: $spacing12;
    }

    &__16 {
        width: $spacing16;
        min-width: $spacing16;
        height: $spacing16;
        min-height: $spacing16;
    }

    &__24 {
        width: $spacing24;
        min-width: $spacing24;
        height: $spacing24;
        min-height: $spacing24;
    }

    &__32 {
        width: $spacing32;
        min-width: $spacing32;
        height: $spacing32;
        min-height: $spacing32;
    }

    &__40 {
        width: $spacing40;
        min-width: $spacing40;
        height: $spacing40;
        min-height: $spacing40;
    }

    &__48 {
        width: $spacing48;
        min-width: $spacing48;
        height: $spacing48;
        min-height: $spacing48;
    }

    &__56 {
        width: $spacing56;
        min-width: $spacing56;
        height: $spacing56;
        min-height: $spacing56;
    }

    &__72 {
        width: $spacing72;
        min-width: $spacing72;
        height: $spacing72;
        min-height: $spacing72;
    }

    &__80 {
        width: $spacing80;
        min-width: $spacing80;
        height: $spacing80;
        min-height: $spacing80;
    }
}

.Button {
    &__primary {
        font-size: 15px;
        font-family: 'pretendardBold';
        color: $color-white;
        width: 100%;
        max-width: 189px;
        height: 34px;
        background: var(--color-prim-800);
        border: none;
        border-radius: 10px;

        &:active {
            background: $color-prim-900;
        }

        &:disabled {
            background: $color-gray-100;
        }
    }

    &__examStatement {
        width: fit-content;
        background: var(--color-prim-100);
        color: var(--color-prim-800);
        border: none;
        height: fit-content;
        min-height: 25px;
        border-radius: 10px;
        padding: 12px 14px;

        &:active {
            background: var(--color-prim-200);
        }

        * {
            text-align: left;
        }

        &__md {
            @extend .Button__examStatement;
            margin: 0 $spacing16;
        }
    }

    &__reset {
        width: 83px;
        min-width: 83px;
        height: 44px;
        border: 1px solid $color-gray-500;
        border-radius: 100px;
        background: $color-white;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
            background: $color-gray-100;
        }

        &:active {
            background: $color-gray-300;
        }

        &__md {
            background: transparent;
            width: 36px;
            height: 36px;
            border: 1px solid $color-gray-500;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    &__carouselFAB {
        width: 161px;
        height: 38px;
        position: relative;
        top: 0;
        left: 0;
        border: 1px solid $color-gray-300;
        border-radius: 100px;
        background: $color-white;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0 4px 12px 0 rgba($color: $color-gray-900, $alpha: 0.15);
        z-index: 1;
    }

    &__carouselSC {
        width: 100%;
        height: 91px;
        position: relative;
        top: 0;
        left: 0;
        padding: 16px;
        border: none;
        border-bottom: 1px solid $color-gray-300;
        background: rgba($color: $color-white, $alpha: 0.95);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;
    }

    &__subs {
        width: 145px;
        height: 39px;
        background: var(--color-prim-800);
        border: none;
        border-radius: 10px;
        margin-top: 20px;
        margin-right: 14px;

        p {
            font-size: 16px;
            font-weight: 700;
            line-height: 19px;
            color: $color-white;
        }

        @media screen and (max-width: $max-width-web-chat) {
            width: 100%;
            margin: 0;
        }
    }
}

.Label {
    &__badge {
        width: fit-content;
        height: 21px;
        border-radius: 6px;
        background: $color-gray-100;
        padding: 0 $spacing8;

        &__recommended {
            @extend .Label__badge;
            color: var(--color-prim-800);
        }

        &__recommendedFill {
            @extend .Label__badge;
            color: $color-white;
            background: var(--color-prim-800);
        }

        &__related {
            @extend .Label__badge;
            color: $color-gray-500;
        }
    }
}

.Banner {
    &__subsWrap {
        width: 100%;
        padding-left: $spacing40;
        padding-bottom: $spacing16;

        @media screen and (max-width: $max-width-web-chat) {
            &__img2 {
                display: none;
            }
        }

        &__md {
            padding-left: $spacing56;
            padding-right: $spacing16;
            margin: 0;
        }
    }

    &__subs {
        width: 100%;
        background: $color-white;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: $spacing8 $spacing16;

        &__md {
            @extend .Banner__subs;
            padding: $spacing16;
        }

        &__content {
            font-size: 18px;
            // font-weight: $font-weight-semibold;
            font-weight: 700;
            line-height: 32px;

            &__md {
                font-size: 15px;
                font-weight: 600;
                line-height: 21px;
            }
        }

        @media screen and (max-width: $max-width-web-chat) {
            flex-direction: column;
        }
    }

    &__floating {
        &__container {
            position: absolute;
            bottom: 102px;
            right: 16px;
            border-radius: 16px;
            box-shadow: 0 4px 20px 0 rgba($color: $color-gray-900, $alpha: 0.15);
            overflow: hidden;
            z-index: 3;
        }
        
        &__wrap {
            position: relative;
            width: 350px;
            height: fit-content;
            background: $color-white;
            border-radius: 16px;
            z-index: 2;
        }

        &__title {
            font-size: 16px;
            font-weight: $font-weight-semibold;
            line-height: 19px;
        }

        &__content {
            font-size: 14px;
            font-weight: $font-weight-regular;
            line-height: 20px;
            margin-top: $spacing8;
            color: $color-gray-600;
        }

        &__button {
            @extend .Button__primary;
            height: 38px;
            margin-left: 16px;
            font-weight: $font-weight-bold;
        }

        &__minimize {
            &__small {
                &__button {
                    @extend .Banner__floating__button;
                    height: fit-content;
                    padding: $spacing16;
                    border-radius: 16px;
                    margin: 0;
                }

                &__img {
                    vertical-align: middle;
                    margin-right: $spacing4;
                }
            }
        }
    }
}

.Indicator {
    &__line {
        &__container {
            display: flex;
            gap: 0px;
            justify-content: center;
            align-items: center;
            width: 20%;
            border-radius: 999px;
            overflow: hidden;
        }

        &__dot {
            width: 6px;
            height: 4px;
            border-radius: 999px;
            background-color: $color-gray-300;
            transition: background-color 0.1s ease-in-out;
        }
    }
}