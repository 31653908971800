@import './global.scss';
@import './guideVar.scss';

@mixin animate($animation,$duration,$method,$times){
  animation: $animation $duration $method $times;
}

@mixin keyframes($name){
  @keyframes #{$name}{
      @content;
  }
}

@include keyframes(bounce) {
    0% {
        transform: translateX(-16px);
    }
    0.14% {
        transform: translateX(-16px);
    }
    28.55% {
        transform: translateX(12px);
    }
    28.69% {
      transform: translateX(12px);
    }
    57.1% {
        transform: translateX(-4px);
    }
    57.24% {
      transform: translateX(-4px);
    }
    78.55% {
        transform: translateX(2px);
    }
    78.69% {
      transform: translateX(2px);
    }
    100% {
        transform: translateX(0);
    }
}

@mixin animate($animation,$duration,$method,$times){
  animation: $animation $duration $method $times;
}

@mixin keyframes($name){
  @keyframes #{$name}{
      @content;
  }
}

@include keyframes(bounce) {
    0% {
        transform: translateX(-16px);
    }
    0.14% {
        transform: translateX(-16px);
    }
    28.55% {
        transform: translateX(12px);
    }
    28.69% {
      transform: translateX(12px);
    }
    57.1% {
        transform: translateX(-4px);
    }
    57.24% {
      transform: translateX(-4px);
    }
    78.55% {
        transform: translateX(2px);
    }
    78.69% {
      transform: translateX(2px);
    }
    100% {
        transform: translateX(0);
    }
}

.Display {
  &__Container {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    position: relative;
  }

  &__ToggleButtonContainer {
    width: 24px;
    height: 24px;
    position: absolute;
    top: 6px;
    left: 16px;
  }

  &__ToggleButton {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  &__CarouselAlarmBadge {
    position: absolute;
    top: 13px;
    right: 16px;
    z-index: 1000;
    padding: $spacing4 $spacing6;
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background: $color-gray-700;
  }
}

.TopHeader {
  &__container {
      width: 100%;
      height: 34px;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
  }

  &__divider {
      width: 100%;
      height: 5px;
      background-color: $color-gray-200;
      border-bottom: 1px solid $color-gray-300;
  }
}

.ProductList {
    &__productList {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-height: 142px;
      justify-content: flex-start;
      padding: 4px 0;
      gap: 4px;
    }
    
    &__productItem {
      width: 100%;
      height: fit-content;
      min-height: 50px;
      max-height: 50px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      // padding: 8px 16px;
    }
    
    &__productImage {
      width: 34px;
      min-width: 34px;
      height: 34px;
      min-height: 34px;
      object-fit: cover;
      border: 1px solid $color-gray-200;
      border-radius: 4px;
      overflow: hidden;
    }
    
    &__productInfoContainer {
      width: 100%;
    }
    
    &__productHeader {
      width: 100%;
      display: flex;
      flex-direction: column;
    }
    
    &__companyName {
      @extend .h8; 
      @extend .fc-gray-500;
    }
    
    &__productName {
      @extend .h9;
      @extend .fc-gray-800;
    }
    
    &__productDetails {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
    }
    
    &__discountRate {
      @extend .h8;
      @extend .fc-warning;
    }
    
    &__price {
      @extend .h8;
      @extend .fc-gray-800;
    }
    
    &__reviewContainer {
      display: flex;
      align-items: center;
    }
    
    &__reviewScore {
      @extend .h9;
      @extend .fc-gray-500;
    }
    
    &__paginationContainer {
      width: 100%;
      height: 40px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 10px 16px;
    }
}

.ProductListVertical {
    &__productList {
        display: flex;
        width: 100%;
        max-height: 142px;
        justify-content: flex-start;
        padding: 8px 0 4px 0;
        gap: 8px;
        scroll-snap-align: start;

        &__shrink {
            @extend .ProductList__productList;
            max-height: 42px;
            flex-direction: row;
        }
    }

    &__productItem {
        width: 33%;
        height: 100%;
        min-height: 42px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        text-align: left;
        
        &__shrink {
            @extend .ProductList__productItem;
            width: 100%;
            padding: 8px 56px 0 56px;
        }
    }

    &__productImageWrapper {
        position: relative;
        width: 100%;
        height: 91px;
        min-height: 91px;
        border: 1px solid $color-gray-200;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
    }

    &__productImageBadge {
        position: absolute;
        top: 0;
        left: 0;
        width: 32px;
        height: 25px;
        background-color: $color-gray-800;
        border-radius: 0 0 8px 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__productImage {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.ProductListHorizontal {
    &__productList {
        display: flex;
        flex-direction: column;
        width: 100%;  
    }

    &__productItem {
        width: 100%;
        height: 50px;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: $spacing8 0;
    }

    &__productImageWrapper {
        min-width: 34px;
        width: 34px;
        height: 34px;
        border: 1px solid $color-gray-200;
        border-radius: 4px;
        overflow: hidden;
    }

    &__productImage {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.ProductSingle {
    &__container {
        width: 100%;
        height: 100%;
        overflow: hidden;
        position: relative;
        touch-action: none;
    }

    &__wrapper {
        width: fit-content;
        height: 100%;
        display: flex;
        overflow-x: scroll;
        position: relative;
        scroll-behavior: smooth;
        -webkit-overflow-scrolling: touch;
        white-space: nowrap;
        scroll-snap-type: x mandatory;
        -ms-overflow-style: none;  /* Hide scrollbar for IE and Edge */
        scrollbar-width: none;  /* Hide scrollbar for Firefox */
        touch-action: pan-x;
    }
    
    &__wrapper::-webkit-scrollbar {
        display: none;
    }
    
    &__cardWrapper {
        width: 100vw;
        min-width: 100vw;
        height: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        padding: 14px 16px 6px 16px;
        scroll-snap-align: start;
    }
    
    &__productInfo {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
    }

    &__companyName {
        @extend .h8;
        @extend .fc-gray-500;
        min-height: 16px;
        height: 16px;
    }
    
    &__productName {
        @extend .h6;
        @extend .fc-gray-800;
        width: 100%;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    &__priceWrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
    }
    
    &__productPrice {
        @extend .h4;
        @extend .fc-gray-800;
    }

    &__discountRate {
        @extend .h4;
        @extend .fc-warning;
    }

    &__reviewWrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
    }

    &__reviewScore {
        @extend .h9;
        @extend .fc-gray-500;
    }

    &__detailButton {
        width: 100%;
        height: 34px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #e1e1e1;
        border-radius: 8px;
        padding: 0 8px;
    }

    &__detailButtonText {
        @extend .h8;
        @extend .fc-gray-600;
    }
}

.bounce {
  @include animate(bounce, 704ms, ease-in-out, 1);
}


// .dynamicHeight {
//   height: calc(#{$product-count} * 42px);
//   max-height: 142px;
// } 